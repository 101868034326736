import { useContext } from 'react';
import { InfoCardDispatchContext, InfoCardStateContext } from './context';

interface UseInfoCard {
    open: boolean;
    toggleDisabled: boolean;
    setOpen: (open: boolean) => void;
    toggle: () => void;
    disableToggle: () => void;
}

export function useInfoCard(): UseInfoCard {
    const state = useContext(InfoCardStateContext);
    const dispatch = useContext(InfoCardDispatchContext);

    if (
        typeof state.open !== 'boolean' ||
        typeof dispatch.setOpen !== 'function' ||
        typeof dispatch.toggle !== 'function' ||
        typeof dispatch.disableToggle !== 'function'
    ) {
        throw new Error('useInfoCard must be used within a InfoCardProvider');
    }

    return {
        open: state.open,
        toggleDisabled: state.toggleDisabled,
        setOpen: dispatch.setOpen,
        toggle: dispatch.toggle,
        disableToggle: dispatch.disableToggle,
    };
}
