import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';

export const ICS_CORRELATION_NAME = 'x-ics-correlation-id';

export const setCorrelationId = (correlationId: string) => {
    sessionStorage.setItem(ICS_CORRELATION_NAME, correlationId);
};

export const getCorrelationId = () => {
    return sessionStorage.getItem(ICS_CORRELATION_NAME);
};

export const hasCorrelationId = (): boolean => {
    return !!sessionStorage.getItem(ICS_CORRELATION_NAME);
};

export const removeCorrelationId = () => {
    sessionStorage.removeItem(ICS_CORRELATION_NAME);
};

function supportsCrypto() {
    return 'crypto' in window && !!window.crypto.randomUUID;
}

/**
 * Generate a UUID
 * If the browser supports the `crypto.randomUUID` method, use it
 * Otherwise, use the `uuidv4` library
 * @docs https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
 */
const generateUUID = (): string => {
    if (supportsCrypto()) {
        return window.crypto.randomUUID();
    } else {
        return uuidv4();
    }
};

/**
 * A hook to set/get/sync a correlation id for the current session
 * using the sessionStorage
 *
 * @note This hook should only be used in a root component, multiple uses could cause conflicts
 */
export default function useCorrelationId() {
    /**
     * Generate a correlation id if it doesn't exist
     * and remove it when the component is unmounted
     */
    useEffect(() => {
        if (!hasCorrelationId()) {
            setCorrelationId(generateUUID());
        }
        return () => {
            removeCorrelationId();
        };
    }, []);

    return getCorrelationId();
}
