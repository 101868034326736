import { jsxs, jsx } from 'react/jsx-runtime';

function LoadingBlur(_ref) {
  let {
    loading,
    className,
    style,
    children
  } = _ref;
  return jsxs("div", {
    className: ['ics-loading-blur__container', className].join(' '),
    style: style,
    children: [jsx("div", {
      className: loading ? 'ics-loading-blur' : '',
      style: style,
      children: children
    }), loading ? jsx("div", {
      className: "orbit"
    }) : null]
  });
}

export { LoadingBlur };
