import { ReactNode } from 'react';
import useUser from '../../hooks/useUser';

type Props = {
    userRoles: string[];
    allowedRoles: string[];
    children: ReactNode;
};

export default function RoleBasedViewAccess({
    userRoles,
    allowedRoles,
    children,
}: Props) {
    const user = useUser();

    if (
        !user?.admin &&
        !userRoles.some((uRole) =>
            allowedRoles.some((aRole) => uRole === aRole),
        )
    ) {
        return null;
    }

    return <>{children}</>;
}
