import type { ReactNode } from 'react';
import styled from 'styled-components';
import type { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Flex } from '@ics-portal/react';
import { NavLink } from 'react-router-dom';
import routes from '../../navigation/routes';
import ViewModeSelector from '../../ViewModeSelector';

const Crumb = styled(NavLink)`
    text-decoration: none;
    margin: 4px 0;
    transition: background-color ease-in-out 300ms;
    outline-color: var(--ics-outline-color);
    color: var(--ics-text-primary);
    position: relative;

    &:not(:last-child):after {
        content: '';
        display: block;
        border: solid currentColor;
        border-width: 2px 2px 0 0;
        position: absolute;
        top: 47%;
        right: -1em;
        transform: rotate(45deg) translateY(-55%);
        width: 0.35em;
        height: 0.35em;
    }

    &[aria-current='page'] {
        font-family: 'Siemens Sans', sans-serif;
        font-weight: 700;

        color: var(--ics-text-primary);
        pointer-events: none;
    }
`;

const Header = styled.header`
    border-bottom: solid 0.125rem var(--ics-neutrals-n50);
    padding: var(--ics-content-padding) var(--ics-gutter-xxl);
`;

interface Props {
    title: ReactNode;
    className?: string;
    children?: ReactNode;
}

export default function ContentController({
    className,
    title,
    children,
}: Props) {
    const breadcrumbs = useBreadcrumbs(routes as BreadcrumbsRoute[]);

    return (
        <Header className={className}>
            <Flex aria-label="breadcrumbs" gap="lg" jc="flex-start">
                {breadcrumbs.map(({ match, breadcrumb }) => (
                    <Crumb end key={match.pathname} to={match.pathname}>
                        {breadcrumb}
                    </Crumb>
                ))}
            </Flex>
            <Flex>
                {typeof title === 'string' ? <h1>{title}</h1> : title}
                {children ? children : <ViewModeSelector />}
            </Flex>
        </Header>
    );
}
