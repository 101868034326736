import { jsxs, jsx } from 'react/jsx-runtime';

/**
 * Displays a message to the user at the bottom left side of the screen
 */
function Snackbar(_ref) {
  let {
    type,
    icon,
    content
  } = _ref;
  return jsxs("div", {
    className: `ics-snackbar-compact ics-snackbar-compact--${type}`,
    children: [icon, jsx("span", {
      children: content
    })]
  });
}

export { Snackbar };
