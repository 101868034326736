import type { IconProps, IconTypes } from '@ics-portal/react';
import { Icon } from '@ics-portal/react';

const iconComponentGenerator = (iconName: IconTypes) =>
    function IconComponent(props: Omit<IconProps, 'name'>) {
        return <Icon name={iconName} {...props} />;
    };

/**
 * Global level icons
 * Represent the bigger areas of the application, such as the different pages.
 */
// Page - Projects
export const ProjectsIcon = iconComponentGenerator('dashboard');

// Page - Security Notifications
export const SecurityNotificationIcon = iconComponentGenerator('gpp_maybe');

// Page - My Tasks
export const MyTaskIcon = iconComponentGenerator('checkbook');

// Page - Component Library
export const ComponentLibraryIcon = iconComponentGenerator('shapes');

// Page - Standards Library
export const StandardsLibraryIcon = iconComponentGenerator('library_books');

// Page - Adminitration
export const AdministrationIcon = iconComponentGenerator(
    'admin_panel_settings',
);

/**
 * Project area icons
 * Represent the different sections of the project page.
 */

// Page - Project Summary
export const ProjectSummaryIcon = iconComponentGenerator('dashboard');

// Page - Project Tasks
export const ProjectTasksIcon = iconComponentGenerator('task_alt');

// Page - Project PSSP Classification
export const ProjectPSSPClassificationIcon = iconComponentGenerator('check');

// Page - Vulnerability Management
export const ProjectVulnerabilityManagementIcon =
    iconComponentGenerator('healing');

// Page - Security Zones
export const ProjectSecurityZonesIcon =
    iconComponentGenerator('network_locked');

// Page - Assets and Items
export const ProjectAssetsAndItemsIcon = iconComponentGenerator('stack');

export const ProjectItemsIcon = iconComponentGenerator('stack');

// Page - Project Components
export const ProjectComponentsIcon = ComponentLibraryIcon;
// @note We are using the same icons as for the global "components" page

/**
 * Admin area icons
 * Represent the different sections of the admin page.
 */

// Page - Admin Users
export const AdminUsersIcons = iconComponentGenerator('group');

// Page - Admin Standards
export const AdminStandardsIcons = iconComponentGenerator('library_books');

// Page - Admin Requirements
export const AdminRequirementsIcons = iconComponentGenerator('library_books');

// Page - Admin Mappings
export const AdminMappingsIcons = iconComponentGenerator('beenhere');

// Page - Admin PSS Project Classification Templates
export const AdminPSSProjectClassificationTemplatesIcons =
    iconComponentGenerator('book');

// Project Types
export const ProjectTypeCustomerIcon = iconComponentGenerator('handshake');
export const ProjectTypeProductDevelopmentIcon = iconComponentGenerator('book');
export const ProjectTypeStandardSolutionIcon =
    iconComponentGenerator('description');
export const ProjectTypeServiceIcon = iconComponentGenerator(
    'center_focus_strong',
);
export const ProjectTypeFactoryLifecycleIcon =
    iconComponentGenerator('factory');
export const ProjectTypeDefaultIcon = iconComponentGenerator('square');

/**
 * Grid icons
 */
export const FilterListIcon = iconComponentGenerator('filter_list');
export const ReorderIcon = iconComponentGenerator('reorder');
export const ExpandCircleDownIcon =
    iconComponentGenerator('expand_circle_down');
export const FilterListOffIcon = iconComponentGenerator('filter_list_off');
export const SortUpIcon = iconComponentGenerator('arrow_upward');
export const SortDownIcon = iconComponentGenerator('arrow_downward');
export const UnSortedIcon = iconComponentGenerator('sort');

/**
 * Secondary area icons
 */

// Function - Send feedback/report a bug
export const SendFeedbackIcon = iconComponentGenerator('mail');

// Function - Cancel
export const CancelIcon = iconComponentGenerator('cancel');

// Function - Download
export const DownloadIcon = iconComponentGenerator('cloud_download');

// Function - Upload
export const UploadIcon = iconComponentGenerator('cloud_upload');

// Function - Show more options
export const MoreOptionsIcon = iconComponentGenerator('more_vert');

// Function - Show more options
export const AddIcon = iconComponentGenerator('add');

// Function - Arrow go forward
export const ForwardIcon = iconComponentGenerator('arrow_forward');

// Function - Arrow north east
export const NorthEastIcon = iconComponentGenerator('north_east');

// Function - Chevron left
export const ChevronLeftIcon = iconComponentGenerator('chevron_left');

// Function - Chevron right
export const ChevronRightIcon = iconComponentGenerator('chevron_right');

// Function - Chevron up
export const ChevronUpIcon = iconComponentGenerator('keyboard_arrow_up');

// Function - Chevron down
export const ChevronDownIcon = iconComponentGenerator('keyboard_arrow_down');

// Function - To the start chevron
export const ChevronStartIcon = iconComponentGenerator('first_page');

// Function - Settings
export const SettingsIcon = iconComponentGenerator('settings');

// Function - Error
export const ErrorIcon = iconComponentGenerator('error');

// Function - Info
export const InfoIcon = iconComponentGenerator('info');

// Function - Check
export const CheckIcon = iconComponentGenerator('check_circle');

// Function - Help
export const HelpIcon = iconComponentGenerator('help');

// Function - Compact view mode
export const CompactViewIcon = iconComponentGenerator('list');

// Function - Roomy view mode
export const RoomyViewIcon = iconComponentGenerator('lists');

// Function - Roomy view mode
export const CopyToClipboardIcon = iconComponentGenerator('content_copy');

// Function - Edit
export const EditIcon = iconComponentGenerator('edit');

// Function - Delete forever
export const DeleteForeverIcon = iconComponentGenerator('delete_forever');

// Function - Close
export const CloseIcon = iconComponentGenerator('close');

// Function - Search
export const SearchIcon = iconComponentGenerator('search');

// Function - Calendar
export const CalendarIcon = iconComponentGenerator('calendar_month');

// TabIcons
export const InfoTabIcon = InfoIcon;
export const SummarizeTabIcon = iconComponentGenerator('summarize');
export const BallotTabIcon = iconComponentGenerator('ballot');
export const CommentTabIcon = iconComponentGenerator('comment');
export const ProjectComponentsTabIcon = ProjectComponentsIcon;
export const ProjectsTabIcon = ProjectsIcon;
export const SecurityNotificationTabIcon = SecurityNotificationIcon;
export const HelpTabIcon = HelpIcon;
