import { graphql } from '~/gql';

export const GET_COMPONENTS = graphql(`
    query GetComponents(
        $first: Int
        $last: Int
        $after: Cursor
        $before: Cursor
        $orderBy: ComponentOrderField
        $orderDirection: OrderDirection!
        $search: String = ""
    ) {
        components(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { field: $orderBy, direction: $orderDirection }
            where: {
                or: [
                    { nameContainsFold: $search }
                    { hasComponentVendorWith: { nameContainsFold: $search } }
                ]
            }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    ...ComponentFragment
                }
                cursor
            }
        }
    }
`);

export const GET_COMPONENTS_SERVER = graphql(`
    query GetComponentsServer(
        $orderBy: ComponentOrder
        $where: ComponentWhereInput
        $first: Int
        $before: Cursor
        $after: Cursor
    ) {
        components(
            where: $where
            orderBy: $orderBy
            first: $first
            before: $before
            after: $after
        ) {
            totalCount
            edges {
                cursor
                node {
                    ...ComponentFragment
                }
            }
        }
    }
`);

export const GET_COMPONENTS_AFFECTED_BY_SECURITY_NOTIFICATION = graphql(`
    query GetComponentsAffectedByNotification($notificationId: ID!) {
        notifications(where: { id: $notificationId }) {
            totalCount
            edges {
                node {
                    id
                    source
                    body
                    components {
                        id
                        name
                        version
                        isValid
                        componentVendor {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`);

// It's not clear in the documentation but this endpoint will only give the components that are used in the requesting user's projects
export const GET_USER_RELEVANT_COMPONENTS_AFFECTED_BY_SECURITY_NOTIFICATION =
    graphql(`
        query GetUserRelevantComponentsAffectedByNotification(
            $notificationId: ID!
        ) {
            componentsAffectedByNotification(notificationID: $notificationId) {
                edges {
                    node {
                        id
                        name
                        affectedProjectsCount
                        componentVendorID
                        componentVendorName
                        version
                        isValid
                        affectedAssetsCount
                    }
                }
            }
        }
    `);

export const GET_COMPONENTS_AFFECTED_BY_SECURITY_NOTIFICATION_IN_PROJECT =
    graphql(`
        query GetComponentsAffectedByNotificationInProject(
            $pid: ID!
            $notificationId: ID!
        ) {
            componentsAffectedByNotificationInProject(
                where: {
                    and: [
                        { notificationID: $notificationId }
                        { projectID: $pid }
                    ]
                }
            ) {
                edges {
                    node {
                        id
                        name
                        componentVendorID
                        componentVendorName
                        version
                        isValid
                        affectedAssetsCount
                    }
                }
            }
        }
    `);

export const FIND_COMPONENTS = graphql(`
    query FindComponents($search: String!) {
        components(
            where: {
                or: [
                    { nameContainsFold: $search }
                    { hasComponentVendorWith: { nameContainsFold: $search } }
                ]
            }
        ) {
            totalCount
            edges {
                node {
                    ...ComponentPartialFragment
                }
            }
        }
    }
`);

export const SEARCH_COMPONENT_NAMES = graphql(`
    query SearchComponentNames(
        $search: String = ""
        $first: Int
        $last: Int
        $after: Cursor
        $before: Cursor
        $orderBy: ComponentOrderField
        $orderDirection: OrderDirection!
    ) {
        components(
            where: {
                or: [
                    { nameContainsFold: $search }
                    { hasComponentVendorWith: { nameContainsFold: $search } }
                ]
            }
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderDirection, field: $orderBy }
        ) {
            edges {
                node {
                    id
                    name
                    componentVendor {
                        name
                    }
                    version
                }
                cursor
            }
            totalCount
        }
    }
`);

export const GET_SINGLE_COMPONENT = graphql(`
    query GetComponent($componentId: ID) {
        components(where: { id: $componentId }) {
            edges {
                node {
                    __typename
                    id
                    name
                    version
                    componentVendor {
                        id
                        name
                    }
                    eolDate
                    eolReached
                    cpeName
                    monitoredSince
                    url
                    securityUrl
                    isValid
                    componentExternalIdentifiers {
                        id
                        source
                        externalIdentifier
                    }
                }
            }
        }
    }
`);

export const GET_SINGLE_COMPONENT_EXTERNAL_IDENTIFIERS = graphql(`
    query GetComponentExternalIdentifiers($componentId: ID) {
        components(where: { id: $componentId }) {
            edges {
                node {
                    componentExternalIdentifiers {
                        id
                        source
                        externalIdentifier
                    }
                }
            }
        }
    }
`);

export const GET_SINGLE_COMPONENT_NAME_ONLY = graphql(`
    query getComponentNameOnly($componentId: ID!) {
        components(where: { id: $componentId }) {
            edges {
                node {
                    name
                }
            }
        }
    }
`);
