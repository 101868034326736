import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';

/**
 * Display an avatar with the initials of the user
 */
function Avatar(_a) {
  var {
      children,
      size = 'normal',
      className
    } = _a,
    rest = __rest(_a, ["children", "size", "className"]);
  const classes = clsx('ics-avatar', {
    [`ics-avatar--${size}`]: size
  }, className);
  return jsx("div", Object.assign({
    className: classes
  }, rest, {
    children: children
  }));
}

export { Avatar as default };
