import { createContext } from 'react';
import type {
    InfoCardDispatchContext as DispatchType,
    InfoCardStateContext as StateType,
} from './types';

const defaultState = {
    open: false,
    toggleDisabled: false,
} satisfies StateType;

const defaultDispatch = {
    setOpen: () => {},
    toggle: () => {},
    disableToggle: () => {},
} satisfies DispatchType;

export const InfoCardStateContext = createContext<StateType>(defaultState);
export const InfoCardDispatchContext =
    createContext<DispatchType>(defaultDispatch);
