import { jsx } from 'react/jsx-runtime';

function Card(_ref) {
  let {
    children,
    backgroundColor = 'white',
    dropShadow = false,
    style,
    className,
    noPadding = false,
    padding,
    border,
    borderStyle,
    title,
    onClick
  } = _ref;
  const bgColorClass = `ics-card--${backgroundColor}`;
  const dropShadowClass = dropShadow ? 'ics-card--shadow' : '';
  const paddingClass = noPadding ? 'ics-card--no-padding' : '';
  const paddingsClass = padding ? `ics-card--padding-${padding}` : '';
  const bordersClass = border ? `ics-card--border-${border}` : '';
  const borderStyleClass = borderStyle ? `ics-card--border-${borderStyle}` : '';
  const handleOnInteractive = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };
  return jsx("div", {
    className: ['ics-card', bgColorClass, dropShadowClass, paddingClass, paddingsClass, bordersClass, borderStyleClass, className].join(' '),
    onClick: handleOnInteractive,
    onKeyDown: handleOnInteractive,
    role: "button",
    style: style,
    tabIndex: 0,
    title: title,
    children: children
  });
}

export { Card };
