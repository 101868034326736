import { useQuery } from '@apollo/client';
import type { BreadcrumbMatch } from 'use-react-router-breadcrumbs';
import { GET_SINGLE_ASSET_NAME_ONLY } from '../../api/queries/assets';
import {
    GET_SINGLE_COMPONENT,
    GET_SINGLE_COMPONENT_NAME_ONLY,
} from '../../api/queries/components';
import { GET_SINGLE_ZONE_NAME_ONLY } from '../../api/queries/projectZones';
import { GET_SINGLE_PROJECT_NAME_ONLY } from '../../api/queries/projects';
import { GET_SINGLE_REQUIREMENT_NAME_ONLY } from '../../api/queries/requirements';
import { GET_PROFILE_NAME_ONLY } from '../../api/queries/standardProfiles';
import { GET_SINGLE_STANDARD_NAME_ONLY } from '../../api/queries/standards';

interface MatchProps {
    match: BreadcrumbMatch;
}

export function getStandardNameFromId(id: string | undefined) {
    const { error, data } = useQuery(GET_SINGLE_STANDARD_NAME_ONLY, {
        variables: { sid: id ?? '' },
    });
    if (error || !data) return '...';
    return data.standards.edges[0]?.node.name || '...';
}

function getStandardBreadcrumb({ match }: MatchProps) {
    return getStandardNameFromId(match.params.sid);
}

export function useProjectNameFromId(id: string | undefined): string {
    const { error, data } = useQuery(GET_SINGLE_PROJECT_NAME_ONLY, {
        skip: !id,
        variables: { pid: id ?? '' },
    });
    if (error ?? !data) return '...';
    return data.projects?.edges[0]?.node.name ?? '...';
}

function useProjectBreadcrumb({ match }: MatchProps) {
    return useProjectNameFromId(match.params.pid);
}

export function getRequirementNameFromId(id: string | undefined) {
    const { error, data } = useQuery(GET_SINGLE_REQUIREMENT_NAME_ONLY, {
        variables: { rid: id ?? '' },
    });

    if (error ?? !data) return '...';
    return data.requirements.edges[0]?.node.name ?? '...';
}

function getRequirementBreadcrumb({ match }: MatchProps) {
    return getRequirementNameFromId(match.params.rid);
}

function getStandardProfileBreadcrumb({ match }: MatchProps) {
    const { data } = useQuery(GET_PROFILE_NAME_ONLY, {
        variables: { profileId: match.params.profileId ?? '' },
        skip: !match.params.profileId,
    });

    return data?.standardProfiles.edges[0]?.node.name ?? '...';
}

export function getProjectZoneNameFromId(id: string | undefined) {
    const { error, data } = useQuery(GET_SINGLE_ZONE_NAME_ONLY, {
        variables: { pzid: id ?? '' },
    });
    if (error ?? !data) return '...';
    return data.projectZones.edges[0]?.node.name || '...';
}

function getProjectZoneBreadcrumb({ match }: MatchProps) {
    return getProjectZoneNameFromId(match.params.nodeId);
}

export function getAssetNameFromId(id: string | undefined) {
    const { error, data } = useQuery(GET_SINGLE_ASSET_NAME_ONLY, {
        variables: { assetId: id ?? '' },
    });
    if (error ?? !data) return '...';
    return data.assets.edges[0]?.node.name || '...';
}

function getAssetBreadcrumb({ match }: MatchProps) {
    return `${getAssetNameFromId(match.params.assetId)} items`;
}

export function getComponentNameFromId(id: string | undefined) {
    const { error, data } = useQuery(GET_SINGLE_COMPONENT_NAME_ONLY, {
        variables: { componentId: id ?? '' },
    });
    if (error ?? !data) return '...';
    return data.components.edges[0]?.node.name || '...';
}

export function getComponentFromId(id: string | null) {
    const { error, data } = useQuery(GET_SINGLE_COMPONENT, {
        variables: { componentId: id ?? '' },
    });
    if (error ?? !data) return null;
    return data.components.edges[0]?.node ?? null;
}

function getComponentBreadcrumb({ match }: MatchProps) {
    return getComponentNameFromId(match.params.componentId);
}

export const securityPriorityTypesAsStrings = {
    P01CRITICAL: 'critical',
    P02MAJOR: 'major',
    P03MINOR: 'minor',
    P04INFORMATION: 'information',
    P05WITHDRAWN: 'withdrawn',
};

export default [
    {
        path: '/projects/create',
        breadcrumb: 'Create',
    },
    {
        path: '/projects/:pid',
        breadcrumb: useProjectBreadcrumb,
    },
    {
        path: '/projects/:pid/security-zones/:nodeId',
        breadcrumb: getProjectZoneBreadcrumb,
    },
    {
        path: '/projects/:pid/security-zones/:nodeId/standard-profile/:profileId',
        breadcrumb: getStandardProfileBreadcrumb,
    },
    {
        path: '/projects/:pid/security-zones/:nodeId/standard-profile/:profileId/:rid',
        breadcrumb: getRequirementBreadcrumb,
    },
    {
        path: '/projects/:pid/assets/:assetId',
        breadcrumb: getAssetBreadcrumb,
    },
    {
        path: '/projects/:pid/assets/:assetId/:componentId',
        breadcrumb: getComponentBreadcrumb,
    },
    {
        path: '/standards/:sid',
        breadcrumb: getStandardBreadcrumb,
    },
    {
        path: '/standards/:sid/requirements/:rid',
        breadcrumb: getRequirementBreadcrumb,
    },
];
