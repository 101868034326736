import { useState } from 'react';
import type { ReactNode } from 'react';
import { InfoCardDispatchContext, InfoCardStateContext } from './context';

interface InfoCardProviderProps {
    defaultOpen?: boolean;
    children: ReactNode;
}

export function InfoCardProvider({
    defaultOpen = false,
    children,
}: InfoCardProviderProps): ReactNode {
    const [open, setOpen] = useState(defaultOpen);
    const [toggleDisabled, setToggleDisabled] = useState(false);

    function toggle(): void {
        setOpen((prev) => !prev);
    }

    function disableToggle(): void {
        setOpen(true);
        setToggleDisabled(true);
    }

    return (
        <InfoCardStateContext.Provider value={{ open, toggleDisabled }}>
            <InfoCardDispatchContext.Provider
                value={{ setOpen, toggle, disableToggle }}
            >
                {children}
            </InfoCardDispatchContext.Provider>
        </InfoCardStateContext.Provider>
    );
}
