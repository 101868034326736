// Setup Sentry - important that it is the first thing to be imported
import './instrument';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import { ApolloProvider } from '@apollo/client';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { UrlString } from '@azure/msal-common';

// Note that AG-grid styles should come first so that they can be overridden by our own styles
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import '@ics-portal/styles/index.css';
import 'material-symbols/index.css';
import './style.css';

import { GlobalUiProvider } from './contexts/GlobalUiContext';
import router from './router';
import publicClientApplication from './auth/msal/client';
import apolloClient from './api/client';
import { defaultTheme } from './styles/themes';
import Authenticating from './Authenticating';
import { getCorrelationId } from './hooks/useCorrelationId';
import { InfoCardProvider } from './components/InfoCard';

const container = document.getElementById('root');
const root = createRoot(container as HTMLDivElement);

/**
 * Setup AG-Grid license key and modules
 * @see {@link https://www.ag-grid.com/react-data-grid/modules/}
 */
LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    MasterDetailModule,
    ClipboardModule,
    ColumnsToolPanelModule,
    MenuModule,
    SetFilterModule,
    SideBarModule,
    StatusBarModule,
]);

/**
 * Checks if the hash in the current URL contains known MSAL properties
 * The known properties are available during the authenticating process
 */
function isAuthPopup(): boolean {
    const hash = window.location.hash;
    return UrlString.hashContainsKnownProperties(hash);
}

/**
 * Enable mocking of API requests
 * MSW is a library for mocking GraphQL or HTTP requests using service workers.
 * @see ./mocks directory for more information
 * @see {@link https://mswjs.io/docs/}
 */
async function enableMocking(): Promise<ServiceWorkerRegistration | undefined> {
    if (
        process.env.NODE_ENV === 'development' &&
        import.meta.env.VITE_API_MOCKING_ENABLED === 'true'
    ) {
        const { worker } = await import('./mocks/browser');
        // `worker.start()` returns a Promise that resolves
        // once the Service Worker is up and ready to intercept requests.
        return worker.start({
            // Decide how to react to unhandled requests (i.e. those that do not have a matching request handler).
            // @see https://mswjs.io/docs/api/setup-worker/start#onunhandledrequest
            onUnhandledRequest: 'bypass',
        });
    }
    // Do nothing if mocking is not enabled
}

/**
 * Prevent rendering the entire application if MSAL is in the process of authenticating a user.
 * Rendering the application during MSAL's authentication process may introduce a race condition.
 * This condition can cause conflict while checking a user's logged-in status and any subsequent redirects.
 */
void enableMocking().then(async () => {
    // Initialize MSAL - important that it is done before calling any other MSAL apis/methods
    await publicClientApplication.initialize();

    if (isAuthPopup()) {
        root.render(<Authenticating />);
    } else {
        root.render(
            <StrictMode>
                <MsalProvider instance={publicClientApplication}>
                    <ApolloProvider client={apolloClient}>
                        <GlobalUiProvider>
                            <InfoCardProvider>
                                <ThemeProvider theme={defaultTheme}>
                                    <RouterProvider router={router} />
                                </ThemeProvider>
                            </InfoCardProvider>
                        </GlobalUiProvider>
                    </ApolloProvider>
                </MsalProvider>
            </StrictMode>,
        );
    }
});
