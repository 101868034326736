import type { ReactNode } from 'react';
import { Avatar } from '@ics-portal/react';
import { type ProjectUser } from '~/gql/graphql';
import { ProjectRolesHelper } from '../helpers/projectRoles';
import { useProjectSummary } from '../../../_utils/contexts/ProjectContext';
import s from './projectUser.module.css';

export function ProjectUser({
    projectUser,
}: {
    projectUser: ProjectUser;
}): ReactNode {
    const { project } = useProjectSummary();

    const helper = new ProjectRolesHelper(project);
    const isICSO = helper.isIcso(projectUser.user.id);
    const roleName = ProjectRolesHelper.Roles.find(
        (role) => role.id === projectUser.role?.id,
    )?.name;

    return (
        <div className={s.projectUser}>
            <Avatar>
                {projectUser.user.firstName.charAt(0)}
                {projectUser.user.lastName.charAt(0)}
            </Avatar>
            <div>
                {Boolean(roleName) && (
                    <sup>
                        {roleName} {isICSO ? ' & ICSO' : ''}
                    </sup>
                )}
                {projectUser.user.firstName} {projectUser.user.lastName}
            </div>
        </div>
    );
}
