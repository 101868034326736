import { useQuery } from '@apollo/client';
import { Card, Flex, GridTemplate } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import { GET_COMPONENTS_COUNT_FOR_PROJECT } from '../../../../../../../api/queries/projects';
import ErrorMessage from '../../../../../../../components/ErrorMessage';
import { NorthEastIcon } from '../../../../../../../components/_atoms/icons';
import type { Scalars } from '../../../../../../../gql/graphql';
import CardDescription from '../CardDescription';

interface Props {
    pid: Scalars['ID']['input'];
}
export default function ComponentsCard({ pid }: Props): JSX.Element {
    const { error, data } = useQuery(GET_COMPONENTS_COUNT_FOR_PROJECT, {
        fetchPolicy: 'cache-and-network',
        variables: { pid },
    });

    const components = data?.componentsInProject.totalCount!;

    return (
        <Card backgroundColor="lightGrey" padding="lg">
            <GridTemplate col="minmax(0, 2fr) minmax(0, 1fr)">
                <Flex ai="flex-start" direction="column" gap="sm">
                    <Link className="text-primary hv-text-link" to="components">
                        <Flex display="inline-flex" gap="sm">
                            <h2>{components} Components</h2>
                            <NorthEastIcon />
                        </Flex>
                    </Link>
                    {error ? <ErrorMessage error={error} /> : null}
                    {components === 0 && (
                        <p>
                            No components linked in this project. Add asset and
                            items and link components
                        </p>
                    )}
                    <CardDescription>
                        Components are linked to items. View all project
                        components.
                    </CardDescription>
                </Flex>
            </GridTemplate>
        </Card>
    );
}
