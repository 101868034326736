import { jsx } from 'react/jsx-runtime';

function Pill(_ref) {
  let {
    backgroundColor,
    children,
    style,
    className,
    circle
  } = _ref;
  const colorClass = `ics-pill--background-color-${backgroundColor ? backgroundColor : 'W50'}`;
  return jsx("div", {
    className: ['ics-pill', colorClass, circle ? 'ics-pill--circle' : '', className].join(' '),
    style: style,
    children: children
  });
}

export { Pill };
