import { graphql } from '~/gql';

export const EXPORT_PROJECT_ASSETS_LIST = graphql(`
    query ExportProjectAssetsList($pid: ID!) {
        exportAssetsList(id: $pid) {
            fileName
            content
            type
        }
    }
`);

export const EXPORT_PROJECT_SBOM = graphql(`
    query ExportProjectSBOM($pid: ID!, $format: SBOMExportFormat!) {
        exportProjectSBOM(id: $pid, format: $format) {
            fileName
            type
            content
        }
    }
`);

export const EXPORT_PROJECT_MITIGATION_STATUS = graphql(`
    query ExportProjectMitigationStatus(
        $id: ID!
        $status: NotificationStatusExportFilter!
    ) {
        exportProjectMitigationStatus(
            id: $id
            filterNotificationStatus: $status
        ) {
            fileName
            content
            type
        }
    }
`);

export const EXPORT_USERS = graphql(`
    query exportUsers {
        exportUsers {
            fileName
            type
            content
        }
    }
`);

export const EXPORT_SURVEY_FORM = graphql(`
    query SurveyFormExport($id: ID!) {
        surveyFormExport(id: $id) {
            fileName
            type
            content
        }
    }
`);

export const EXPORT_PROJECT_NOTIFICATIONS = graphql(`
    query ProjectNotificationsReport(
        $pid: ID!
        $from: Time!
        $to: Time!
        $includeMitigations: Boolean!
        $includeAffected: Boolean!
    ) {
        projectNotificationsReport(
            id: $pid
            from: $from
            to: $to
            includeMitigations: $includeMitigations
            includeAffected: $includeAffected
        ) {
            fileName
            content
            type
        }
    }
`);

export const EXPORT_PROJECT_NOTIFICATIONS_DOC = graphql(`
    query ProjectNotificationsReportDoc(
        $pid: ID!
        $from: Time!
        $to: Time!
        $includeMitigations: Boolean!
        $includeAffected: Boolean!
    ) {
        projectNotificationsReportDoc(
            id: $pid
            from: $from
            to: $to
            includeMitigations: $includeMitigations
            includeAffected: $includeAffected
        ) {
            fileName
            content
            type
        }
    }
`);
