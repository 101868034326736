import { jsxs, jsx } from 'react/jsx-runtime';
import { useRef, useEffect } from 'react';
import { Button } from '../Button/Button.js';

// import './Dialog.css';
var DialogState;
(function (DialogState) {
  DialogState["OPEN"] = "OPEN";
  DialogState["CLOSING"] = "CLOSING";
  DialogState["CLOSED"] = "CLOSED";
})(DialogState || (DialogState = {}));
function Dialog(_ref) {
  let {
    title,
    children,
    displayState = DialogState.CLOSED,
    onCancel,
    onOk,
    onClose,
    closeOnBackdropClick = true,
    showButtonsFooter = true,
    className,
    displayType = 'compact',
    width,
    style,
    buttonActions
  } = _ref;
  const ref = useRef(null);
  useEffect(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (displayState === DialogState.OPEN) {
      (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeAttribute('closing');
      if (!((_b = ref.current) === null || _b === void 0 ? void 0 : _b.open)) {
        (_c = ref.current) === null || _c === void 0 ? void 0 : _c.showModal();
        (_d = ref.current) === null || _d === void 0 ? void 0 : _d.addEventListener('keydown', e => {
          if (e.key === 'Escape') {
            e.preventDefault();
            close();
          }
        });
        if (onClose) {
          (_e = ref.current) === null || _e === void 0 ? void 0 : _e.addEventListener('close', () => {
            onClose();
          });
        }
        if (closeOnBackdropClick) {
          (_f = ref.current) === null || _f === void 0 ? void 0 : _f.addEventListener('click', e => {
            const target = e.target;
            if (target.nodeName === 'DIALOG') {
              close();
            }
          });
        }
      }
    }
    if (displayState === DialogState.CLOSING) {
      close();
    }
    if (displayState === DialogState.CLOSED) {
      (_g = ref.current) === null || _g === void 0 ? void 0 : _g.removeAttribute('closing');
      (_h = ref.current) === null || _h === void 0 ? void 0 : _h.close();
    }
  }, [displayState]);
  function close() {
    var _a;
    if ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.open) {
      ref.current.setAttribute('closing', '');
      ref.current.addEventListener('animationend', () => {
        var _a, _b;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeAttribute('closing');
        (_b = ref.current) === null || _b === void 0 ? void 0 : _b.close();
      }, {
        once: true
      });
    }
  }
  const classes = ['ics-dialog', displayType === 'compact' ? 'ics-dialog--compact' : '', displayType === 'edit' ? 'ics-dialog--edit' : '', width === '50rem' ? 'ics-dialog--width-50rem' : '', className].join(' ');
  return jsxs("dialog", {
    className: classes,
    ref: ref,
    style: style,
    children: [title ? jsx("div", {
      className: "ics-dialog--header",
      children: jsx("h1", {
        children: title
      })
    }) : null, jsx("div", {
      className: "ics-dialog--content",
      children: children
    }), showButtonsFooter ? jsxs("div", {
      className: "ics-dialog--footer",
      children: [onCancel ? jsx(Button, {
        label: "Cancel",
        onClick: onCancel,
        variant: "tertiary"
      }) : null, onOk ? jsx(Button, {
        label: "OK",
        onClick: onOk
      }) : null]
    }) : null, Boolean(buttonActions) && jsx("div", {
      className: "ics-dialog--button-actions",
      children: buttonActions
    })]
  });
}

export { Dialog, DialogState };
