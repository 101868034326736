import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import type { PopupRequest, Configuration } from '@azure/msal-browser';

const DEBUG_ENABLED_TARGETS = [
    'localhost',
    'ics-portal.mosaic-dev.siemens-energy.cloud',
];

const authority = `https://login.microsoftonline.com/${import.meta.env.VITE_MSAL_TENANT_ID}`;

const MSAL_DEBUG_ENABLED = import.meta.env.VITE_MSAL_DEBUG_ENABLED === 'true';

export const msalConfig: Configuration = {
    auth: {
        clientId: import.meta.env.VITE_MSAL_CLIENT_ID,
        authority,
        redirectUri: '/',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii || !MSAL_DEBUG_ENABLED) {
                    return;
                }

                if (DEBUG_ENABLED_TARGETS.includes(location.hostname)) {
                    switch (level) {
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        default:
                            return;
                    }
                }

                switch (level) {
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    case LogLevel.Error:
                        console.error(message);

                    default:
                }
            },
        },
    },
};

export const scopes = [
    'email',
    'offline_access',
    import.meta.env.VITE_AAD_API_SCOPE,
];

export const loginPopupRequest: PopupRequest = {
    scopes,
};
