import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { useTooltip } from './use-tooltip.js';
import { TooltipContext } from './context.js';

/**
 * Tooltip
 *
 * @remarks
 * provides a floating tooltip that displays information related to the triggering element
 * when it receives keyboard focus or the mouse hovers over it.
 *
 * @example
 * ```tsx
 * import { Tooltip, TooltipTrigger, TooltipContent } from '@ics-portal/react';
 *
 * <Tooltip>
 *   <TooltipTrigger asChild>
 *       <Button label="Hover over me" />
 *   </TooltipTrigger>
 *   <TooltipContent>I'm a tooltip</TooltipContent>
 * </Tooltip>
 * ```
 */
function Tooltip(_a) {
  var {
      children
    } = _a,
    options = __rest(_a, ["children"]);
  const contextValue = useTooltip(options);
  return jsx(TooltipContext.Provider, {
    value: contextValue,
    children: children
  });
}

export { Tooltip };
