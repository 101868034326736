import * as Sentry from '@sentry/react';
import { onError } from '@apollo/client/link/error';

/**
 * Error link for Apollo Client
 * This link adds the error data to the Sentry scope
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-error/
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
            const { message, name, path, locations } = error;
            Sentry.withScope((scope) => {
                scope.setTag('graphql_error', true);
                scope.setExtra('graphql_error_message', {
                    message,
                    name,
                    path,
                    locations,
                });
                if (path) {
                    scope.addBreadcrumb({
                        category: 'query-path',
                        message: path.join(' > '),
                        level: 'debug',
                    });
                }

                Sentry.captureException(error);
            });
        });
    }

    if (networkError) {
        Sentry.withScope((scope) => {
            scope.setTag('network_error', true);
            Sentry.captureException(networkError);
        });
    }
});

export default errorLink;
