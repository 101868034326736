import {
    BaseSurveyPartialFragment,
    MinimalUserPartialFragment,
    SurveyStatus,
} from '../../../../../gql/graphql';

/**
 * This function returns the information of the next person required to take action in the PSS Project Classification
 *
 * if it was getBestActor it would have returned Gary Oldman
 */
const getNextActor = (
    query: BaseSurveyPartialFragment | undefined | null,
): MinimalUserPartialFragment | undefined | null => {
    const status = query?.status;
    const actors = {
        [SurveyStatus.Draft]: query?.author,
        [SurveyStatus.Readyforapproval1]: query?.approver1User,
        [SurveyStatus.Readyforapproval2]: query?.approver2User,
        [SurveyStatus.Completed]: query?.author,
        [SurveyStatus.Declined]: query?.author,
    };

    if (!status || !actors?.[status]) {
        return null;
    }

    return actors?.[status];
};

export default getNextActor;
