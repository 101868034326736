import { ApolloLink } from '@apollo/client';
import {
    getCorrelationId,
    hasCorrelationId,
} from '../../hooks/useCorrelationId';

/**
 * Apollo middleware to add the "X-Request-ID" to the headers for correlation purposes
 */
const correlationIdLink = new ApolloLink((operation, forward) => {
    // add the "X-Request-ID" to the headers for correlation purposes
    operation.setContext(({ headers = {} }) => {
        if (hasCorrelationId()) {
            return {
                headers: {
                    ...headers,
                    'X-Request-ID': getCorrelationId(),
                },
            };
        }
    });

    return forward(operation);
});

export default correlationIdLink;
