import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import WelcomePage from './pages/WelcomePage';
import App from './App';
import useCorrelationId from './hooks/useCorrelationId';

export default function Root() {
    /**
     * Generate and store a correlation id for the current session
     * used to correlate the frontend logs with the backend logs
     * Is sent both to the backend API and Sentry
     */
    useCorrelationId();

    /**
     * Handle a preload error, for example, if a non-existing module is lazy-loaded
     * which can happen when the user is on an old version of the app and the module has been removed
     * @see https://vitejs.dev/guide/build#load-error-handling
     */
    useEffect(() => {
        function handlePreloadError() {
            window.location.reload();
        }

        window.addEventListener('vite:preloadError', handlePreloadError);

        return () => {
            window.removeEventListener('vite:preloadError', handlePreloadError);
        };
    }, []);

    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) return <WelcomePage />;
    return <App />;
}
