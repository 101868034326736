import { useQuery } from '@apollo/client';
import { GET_PROJECT } from '~/api/queries/projects';
import useUser from '~/hooks/useUser';

interface Config {
    pid: string;
}

export default function useUserProjectRole({ pid }: Config) {
    const { data, loading, error, refetch } = useQuery(GET_PROJECT, {
        variables: { pid },
        fetchPolicy: 'cache-and-network',
    });
    const currentUser = useUser();

    const project = data?.projects?.edges[0]?.node;
    return (
        project?.users?.find((user) => user?.user.id === currentUser?.id)?.role
            ?.name ?? ''
    );
}
