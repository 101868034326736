import { createTheme } from '@mui/material';

const defaultTheme = createTheme({
    typography: {
        fontFamily: '"Siemens Sans", sans-serif',
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'var(--ics-primary-medium-purple-p10)',
                    fontSize: '1rem',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 'var(--page-h4-font-size)',
                    fontFamily: 'var(--page-h4-font-family)',
                    fontWeight: 'var(--page-h4-font-weight)',
                    lineHeight: 'var(--page-h4-line-height)',
                    color: 'var(--ics-neutrals-n50)',
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: 'var(--ics-neutrals-n60)',
                    '&.Mui-checked': {
                        color: 'var(--ics-primary-west-mira-wm90)',
                        transform: 'translateX(19px)',
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: 'var(--ics-primary-west-mira-wm50)',
                    },
                    padding: 13,
                    left: 1,
                },
                track: {
                    opacity: 1,
                    backgroundColor: 'var(--ics-neutrals-n50)',
                },

                thumb: {
                    width: 11,
                    height: 11,
                    color: 'white',
                },
            },
        },
    },
});

export { defaultTheme };
