import {
    type OrganizationUser,
    OrganizationUserRole,
    type Project,
    type ProjectUser,
} from '~/gql/graphql';

interface CommonUserProps {
    id: string;
    lastName: string;
    owner?: boolean;
    icso?: boolean;
    role?: { id: string };
    organizationRole?: OrganizationUserRole;
    member?: boolean;
}

// TODO: use api to get roles
export const DEFAULT_ROLE_ID = 'PR01GB7SMDRETD2N507CMZBZR1QK';
export const OWN_ROLE_ID = 'PR01GB83N5ARY5TZ5XDXWZG0PFV8';

export class ProjectRolesHelper {
    static Roles = [
        {
            id: OWN_ROLE_ID,
            name: 'Project owner',
        },
        {
            id: DEFAULT_ROLE_ID,
            name: 'Project member',
        },
    ];

    private rolePriority(user: CommonUserProps): number {
        if (user.role?.id === OWN_ROLE_ID) {
            return 1;
        }
        if (user.role?.id === DEFAULT_ROLE_ID) {
            return 2;
        }
        if (this.isIcsoOnly(user.id)) {
            return 3;
        }
        return 4;
    }

    sortByRole(a: CommonUserProps, b: CommonUserProps): number {
        return (
            this.rolePriority(a) - this.rolePriority(b) ||
            a.lastName.localeCompare(b.lastName)
        );
    }

    readonly members: string[];
    readonly ICSOs: string[];
    readonly owners: string[];

    constructor(project: Project) {
        const participants = project.participants ?? [];

        this.members = participants
            .filter((participant): participant is ProjectUser => {
                return (
                    participant?.__typename === 'ProjectUser' &&
                    participant.role?.id === DEFAULT_ROLE_ID
                );
            })
            .map(({ user: { id } }) => id);
        this.ICSOs = participants
            .filter((participant): participant is OrganizationUser => {
                return (
                    participant?.__typename === 'OrganizationUser' &&
                    participant.organizationRole === OrganizationUserRole.Icso
                );
            })
            .map(({ user: { id } }) => id);
        this.owners = participants
            .filter((participant): participant is ProjectUser => {
                return (
                    participant?.__typename === 'ProjectUser' &&
                    participant.role?.id === OWN_ROLE_ID
                );
            })
            .map(({ user: { id } }) => id);
    }

    getRole(userId: string): { id: string; name: string } | undefined {
        if (this.isOwner(userId)) {
            return ProjectRolesHelper.Roles.find(
                ({ id }) => id === OWN_ROLE_ID,
            );
        }
        if (this.members.includes(userId)) {
            return ProjectRolesHelper.Roles.find(
                ({ id }) => id === DEFAULT_ROLE_ID,
            );
        }
        return undefined;
    }

    getProjectUserStatus(userId: string): {
        role: { id: string; name: string } | undefined;
        icso: boolean;
        owner: boolean;
    } {
        return {
            role: this.getRole(userId),
            icso: this.isIcso(userId),
            owner: this.isOwner(userId),
        };
    }

    isIcso(userId: string): boolean {
        return this.ICSOs.includes(userId);
    }

    isIcsoOnly(userId: string): boolean {
        return this.isIcso(userId) && !this.isMember(userId);
    }

    isMember(userId: string): boolean {
        return this.members.includes(userId) || this.isOwner(userId);
    }

    isOwner(userId: string): boolean {
        return this.owners.includes(userId);
    }
}
