import { graphql } from '~/gql';

export const GET_REQUIREMENTS = graphql(`
    query GetRequirements {
        requirementsWithSPEs {
            edges {
                node {
                    id
                    name
                    alias
                    description
                    guidance
                    implementationGuidance
                    standardName
                    maturityLevelName
                    securityLevelName
                    subSpeName
                    speName
                }
            }
        }
    }
`);

export const GET_STANDARD_REQUIREMENTS = graphql(`
    query GetStandardRequirements($sid: ID!) {
        standards(where: { id: $sid }) {
            edges {
                node {
                    name
                }
            }
        }
        requirementsWithSPEs(where: { standardID: $sid }) {
            edges {
                node {
                    id
                    name
                    alias
                    description
                    guidance
                    implementationGuidance
                    maturityLevelName
                    securityLevelName
                    subSpeName
                    speName
                }
            }
        }
    }
`);

export const GET_REQUIREMENT_MAPPINGS = graphql(`
    query GetRequirementMappings($requirementId: ID!) {
        requirements(where: { id: $requirementId }) {
            edges {
                node {
                    name
                }
            }
        }
        requirementMappings(
            where: { hasParentRequirementWith: { id: $requirementId } }
        ) {
            totalCount
            edges {
                node {
                    id
                    comment
                    childRequirement {
                        id
                        name
                    }
                    mappingCoverage {
                        name
                    }
                }
            }
        }
    }
`);

export const GET_REQUIREMENTS_FOR_STANDARD_PROFILE = graphql(`
    query GetRequirementsForStandardProfile($pzid: ID!, $spid: ID!) {
        requirementsOnProjectZone(
            where: { projectZoneID: $pzid, standardProfileID: $spid }
        ) {
            totalCount
            edges {
                node {
                    id
                    name
                    requirementCommentStatus
                    alias
                    description
                    guidance
                    implementationGuidance
                    maturityLevelName
                    securityLevelName
                    speName
                    subSpeName
                    requirementCommentCreateTime
                }
            }
        }
    }
`);

export const GET_SINGLE_REQUIREMENT_NAME_ONLY = graphql(`
    query GetRequirementName($rid: ID!) {
        requirements(where: { id: $rid }) {
            edges {
                node {
                    name
                }
            }
        }
    }
`);

export const GET_REQUIREMENT = graphql(`
    query GetRequirement($rid: ID!) {
        requirements: requirementsWithSPEs(where: { id: $rid }) {
            edges {
                node {
                    id
                    name
                    alias
                    description
                    guidance
                    implementationGuidance
                    maturityLevelName
                    securityLevelName
                    speName
                    subSpeName
                }
            }
        }
    }
`);
