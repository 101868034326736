import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { clsx } from 'clsx';

type CardDescriptionProps = HTMLAttributes<HTMLParagraphElement>;

export default function CardDescription({
    children,
    className,
    ...rest
}: PropsWithChildren<CardDescriptionProps>): ReactNode {
    const classes = clsx('m-none text-secondary', className);
    return (
        <p className={classes} {...rest}>
            {children}
        </p>
    );
}
