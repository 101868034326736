import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';

/**
 * The Flex component is a useful layout utility component for creating fine-grained control over layouts using the CSS Flexbox display mode.
 *
 * This allows for common layout patterns to be easily created without needing to write custom CSS. In the case of the Flex component,
 * it uses the old gutter-spacing system, where the Stack component uses a more formal spacing scale.
 *
 * If you are creating a layout that only requires spacing between elements, consider using the simpler Stack component instead.
 */
function Flex(_a) {
  var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
  var {
      children,
      className,
      style
    } = _a,
    props = __rest(_a, ["children", "className", "style"]);
  return jsx("div", {
    className: className,
    style: Object.assign({
      display: !props.display ? 'flex' : props.display,
      justifyContent: (_b = props.jc) !== null && _b !== void 0 ? _b : 'space-between',
      flexDirection: (_c = props.direction) !== null && _c !== void 0 ? _c : 'row',
      flexGrow: (_d = props.grow) !== null && _d !== void 0 ? _d : 0,
      flexBasis: (_e = props.basis) !== null && _e !== void 0 ? _e : 'auto',
      flexShrink: (_f = props.shrink) !== null && _f !== void 0 ? _f : 1,
      flexWrap: (_g = props.fw) !== null && _g !== void 0 ? _g : 'nowrap',
      alignItems: (_h = props.ai) !== null && _h !== void 0 ? _h : 'center',
      gap: props.gap ? `var(--ics-gutter-${props.gap})` : '0',
      width: (_j = props.width) !== null && _j !== void 0 ? _j : 'auto',
      height: (_k = props.height) !== null && _k !== void 0 ? _k : 'auto',
      maxWidth: (_l = props.maxWidth) !== null && _l !== void 0 ? _l : 'none',
      maxHeight: (_m = props.maxHeight) !== null && _m !== void 0 ? _m : 'none',
      minWidth: (_o = props.minWidth) !== null && _o !== void 0 ? _o : 'none',
      minHeight: (_p = props.minHeight) !== null && _p !== void 0 ? _p : 'none',
      boxSizing: 'border-box'
    }, style),
    children: children
  });
}

export { Flex };
