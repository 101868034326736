import { graphql } from '~/gql';

export const GET_STANDARDS = graphql(`
    query GetStandards {
        standards {
            totalCount
            edges {
                node {
                    id
                    name
                    description
                    implementationGuidance
                    validFrom
                    validTo
                    releasedAt
                }
            }
        }
    }
`);

export const GET_SINGLE_STANDARD_NAME_ONLY = graphql(`
    query GetStandardName($sid: ID!) {
        standards(where: { id: $sid }) {
            edges {
                node {
                    name
                }
            }
        }
    }
`);

export const GET_ALL_STANDARD_NAMES = graphql(`
    query GetStandardNames {
        standards {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`);
