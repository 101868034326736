import { jsxs, jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';

function ListableInfo(_ref) {
  let {
    label,
    value,
    size = 'sm',
    orientation = 'horizontal',
    style,
    className,
    labelStyle,
    valueStyle
  } = _ref;
  const classes = clsx('ics-listable-info', {
    [`ics-listable-info--${size}`]: size,
    [`ics-listable-info--${orientation}`]: orientation
  }, className);
  return jsxs("div", {
    className: classes,
    style: style,
    children: [jsx("span", {
      className: "ics-listable-info__label",
      style: labelStyle,
      children: label
    }), jsx("span", {
      className: "ics-listable-info__value",
      style: valueStyle,
      children: value
    })]
  });
}

export { ListableInfo };
