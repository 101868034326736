import { type ReactNode } from 'react';
import { type NavigationItem } from '../../../interfaces/IMenuData';

/**
 * Get the icon for a navigation item
 * @param item
 * @param isActive
 */
export default function getNavigationItemIcon(
    item: NavigationItem,
    isActive = false,
): ReactNode {
    if (item.icon?.default) {
        return <item.icon.default active={isActive} />;
    }

    return null;
}
