import { type ReactNode } from 'react';
import type { ProjectParticipant, ProjectUser } from '~/gql/graphql';
import { ProjectIcsoUser } from './ProjectIcsoUser';
import { ProjectUser as User } from './ProjectUser';

export function ProjectParticipant({
    projectUser,
}: {
    projectUser: ProjectParticipant;
}): ReactNode {
    const isIcso = projectUser.__typename === 'OrganizationUser';

    return isIcso ? (
        <ProjectIcsoUser projectUser={projectUser} />
    ) : (
        <User projectUser={projectUser as ProjectUser} />
    );
}
