import { Suspense } from 'react';
import type { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { clsx } from 'clsx';
import { useGlobalUI } from './contexts/GlobalUiContext';
import AppSidebar from './components/_organisms/sidebar/AppSidebar';
import AppSidebarToggle from './components/_organisms/sidebar/AppSidebarToggle';
import EnvironmentBanner from './components/EnvironmentBanner';
import s from './app.module.scss';

export default function App(): ReactNode {
    const { appClassName, appSidebarHidden } = useGlobalUI();

    const classes = clsx(s.container, {
        [s.hidden]: appSidebarHidden,
        [s.collapsed]: appClassName === 'collapsed',
    });

    return (
        <div className={classes}>
            <main className={s.main}>
                <Suspense fallback={<span>Loading..</span>}>
                    <Outlet />
                </Suspense>
            </main>
            <aside className={s.aside}>
                <EnvironmentBanner />
                <AppSidebar />
                <AppSidebarToggle />
            </aside>
        </div>
    );
}
