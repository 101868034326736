import {
    isValidElement,
    type PropsWithChildren,
    type ReactNode,
    useEffect,
} from 'react';
import { clsx } from 'clsx';
import { InfoCard, useInfoCard } from '../../InfoCard';
import s from './info-card-template.module.scss';

interface InfoCardTemplateProps {
    infoCard?: ReactNode;
    className?: string;
    defaultOpen?: boolean;
    hideToggle?: boolean;
}

export function InfoCardTemplate({
    children,
    infoCard,
    defaultOpen = false,
    className,
    hideToggle,
}: PropsWithChildren<InfoCardTemplateProps>): ReactNode {
    const { open, disableToggle } = useInfoCard();
    const classes = clsx(
        s.infoCardTemplate,
        {
            [s.open]: open || defaultOpen,
        },
        className,
    );

    useEffect(() => {
        if (hideToggle) {
            disableToggle();
        }
    }, [hideToggle, disableToggle]);

    return (
        <div className={classes}>
            <div className={s.contentArea}>{children}</div>
            {isValidElement(infoCard) && <InfoCard>{infoCard}</InfoCard>}
        </div>
    );
}
