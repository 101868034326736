import { useQuery } from '@apollo/client';
import { Card, Flex, GridTemplate } from '@ics-portal/react';
import { Link } from 'react-router-dom';
import { GET_NOTIFICATIONS_SUMMARY_IN_PROJECT } from '../../../../../../../api/queries/projects';
import ErrorMessage from '../../../../../../../components/ErrorMessage';
import { NorthEastIcon } from '../../../../../../../components/_atoms/icons';
import type { Scalars } from '../../../../../../../gql/graphql';
import CardDescription from '../CardDescription';

interface SecurityNotificationsCardProps {
    pid: Scalars['ID']['input'];
}

export default function SecurityNotificationsCard({
    pid,
}: SecurityNotificationsCardProps): JSX.Element {
    const { loading, error, data } = useQuery(
        GET_NOTIFICATIONS_SUMMARY_IN_PROJECT,
        {
            fetchPolicy: 'cache-and-network',
            variables: { pid },
        },
    );

    const total = data?.notificationsSummaryInProject?.reduce((acc, curr) => {
        if (!curr) {
            return acc;
        }

        return acc + curr.total;
    }, 0);

    return (
        <Card backgroundColor="lightGrey" padding="lg">
            <Flex ai="flex-start" direction="column" gap="sm">
                <Link
                    className="text-primary hv-text-link"
                    to="vulnerability/security-notifications"
                >
                    <Flex display="inline-flex" gap="sm">
                        <h2>{total} Security Notifications</h2>
                        <NorthEastIcon />
                    </Flex>
                </Link>
                {error ? <ErrorMessage error={error} /> : null}

                {!loading && total === 0 && (
                    <GridTemplate col="minmax(0, 2fr) minmax(0, 1fr)">
                        <p className="m-none">
                            No security notifications were found for this
                            project, based on the selected components.
                        </p>
                    </GridTemplate>
                )}
                <GridTemplate col="minmax(0, 2fr) minmax(0, 1fr)">
                    <CardDescription>
                        View all notifications for this project.
                    </CardDescription>
                </GridTemplate>
            </Flex>
        </Card>
    );
}
