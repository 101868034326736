import {
    InteractionRequiredAuthError,
    type PublicClientApplication,
} from '@azure/msal-browser';
import { scopes } from './authConfig';

export default async function getToken(
    msal: PublicClientApplication,
): Promise<string | undefined> {
    await msal.initialize();
    const activeAccount = msal.getActiveAccount();
    const accounts = msal.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        throw new Error('No active user found');
    }

    const request = {
        scopes,
        account: activeAccount ?? accounts[0],
    };

    return msal
        .acquireTokenSilent(request)
        .then((accessTokenResponse) => {
            return accessTokenResponse.accessToken;
        })
        .catch((error: unknown) => {
            if (error instanceof InteractionRequiredAuthError) {
                return msal
                    .acquireTokenPopup(request)
                    .then((accessTokenResponse) => {
                        return accessTokenResponse.accessToken;
                    })
                    .catch((error: unknown) => {
                        console.error(error);
                        return undefined;
                    });
            }
        });
}
