import styled from 'styled-components';

const environments: Record<string, string> = {
    'ics-portal.mosaic-dev.siemens-energy.cloud': 'DEV',
    'ics-portal.mosaic-qa.siemens-energy.cloud': 'QA',
};

const Banner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.52rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ics-notification-A70);
    color: var(--ics-text-white);
`;

export default function EnvironmentBanner() {
    if (!Object.keys(environments).includes(window.location.hostname)) {
        return null;
    }

    return (
        <Banner role="complementary">
            {environments[window.location.hostname]}
        </Banner>
    );
}
