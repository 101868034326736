import { Pill } from '@ics-portal/react';

interface Props {
    classification?: string;
}

function PSSClassificationPill({ classification }: Props) {
    return (
        <Pill backgroundColor={getClassificationColor(classification)}>
            {classification}
        </Pill>
    );
}

export default PSSClassificationPill;

const getClassificationColor = (
    classification?: string,
): 'none' | 'A50' | 'W50' | 'O50' | 'S50' => {
    if (!classification) {
        return 'none';
    }

    if (classification === 'low') {
        return 'S50';
    }

    if (classification === 'medium') {
        return 'O50';
    }

    if (classification === 'high') {
        return 'W50';
    }

    if (classification === 'very high') {
        return 'A50';
    }

    return 'none';
};
