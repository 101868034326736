import styled from 'styled-components';
import type { ApolloError } from '@apollo/client';
import { ErrorIcon } from './_atoms/icons';

interface Props {
    error: ApolloError;
    showOnlyMessage?: boolean;
}

const StyledMessage = styled.div`
    font-style: italic;
    padding: 1em 2em;
    background-color: #fff;
    border-radius: 0.75em;
    border: 1px solid #c90000;
    margin: 1em;
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    color: #c90000;
    font-style: normal;

    svg {
        margin-left: 0.35em;
    }
`;

const Bold = styled.span`
    font-family: 'Siemens Sans', sans-serif;
    font-weight: 700;
`;

const Details = styled.div`
    margin-top: 1em;
`;

const GqlError = styled.div`
    margin-top: 0.2em;
    margin-bottom: 1em;
`;

function ErrorMessage({ error, showOnlyMessage }: Props) {
    return (
        <StyledMessage>
            <Heading>
                <Bold>ERROR</Bold>
                <ErrorIcon fontSize="small" />
            </Heading>
            <span>{error.message}</span>
            {error.message
                .toLowerCase()
                .includes('received status code 401') && (
                <p>
                    This might be caused by expired authentication. Please{' '}
                    <a href="/">reload the page and try again</a>.
                </p>
            )}
            {!showOnlyMessage &&
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 ? (
                <Details>
                    <Bold>{error.graphQLErrors.length} GraphQL errors:</Bold>
                    {error.graphQLErrors.map((err, index) => (
                        <GqlError key={`error_${index}`}>
                            <div>
                                MESSAGE: <span>{err.message}</span>
                            </div>
                            {err.path ? (
                                <div>
                                    PATH: <span>{err.path.join(' – ')}</span>
                                </div>
                            ) : null}
                        </GqlError>
                    ))}
                </Details>
            ) : null}
        </StyledMessage>
    );
}

export default ErrorMessage;
