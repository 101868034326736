import { makeUniqueId } from '@apollo/client/utilities';
import { Button } from '@ics-portal/react';
import type { ChangeEventHandler, MouseEvent } from 'react';
import { useRef } from 'react';
import { UploadIcon } from '../../_atoms/icons';

interface Props {
    onChange: ChangeEventHandler<HTMLInputElement>;
    busy?: boolean | undefined;
    label?: string | undefined;
    id?: string | undefined;
    accept?: string | undefined;
}

export default function UploadInput({
    onChange,
    busy,
    label = 'Import',
    id = makeUniqueId('upload-'),
    accept = '.xls,.xlsx,.xlsm',
}: Props): JSX.Element {
    const fileInputRef = useRef<HTMLInputElement>(null);

    function onClick(e: MouseEvent<HTMLInputElement>) {
        if (e.currentTarget.value) e.currentTarget.value = '';
    }

    return (
        <>
            <Button
                disabled={busy}
                icon={<UploadIcon />}
                label={busy ? 'Please wait...' : label}
                onClick={() => fileInputRef.current?.click()}
                variant="tertiary"
            />
            <input
                accept={accept}
                hidden
                id={id}
                name={id}
                onChange={onChange}
                onClick={onClick}
                ref={fileInputRef}
                style={{
                    display: 'none',
                    visibility: 'hidden',
                    width: 0,
                    height: 0,
                }}
                type="file"
            />
        </>
    );
}
