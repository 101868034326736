import {
    createContext,
    type ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import type { OrganizationUser, ProjectParticipant, User } from '~/gql/graphql';
import { useProjectSummary } from '../../../_utils/contexts/ProjectContext';
import { ProjectRolesHelper } from '../helpers/projectRoles';

export interface ProjectMember {
    id: string;
    firstName: string;
    lastName: string;
    role:
        | {
              id: string;
              name: string;
          }
        | undefined;
    icso: boolean;
    participant: ProjectParticipant;
}

interface ProjectMembersContextProps {
    selectedMembers: ProjectMember[];
    selectedICSOs: ProjectMember[];
    removeMember: (id: string) => void;
    addMember: (user: User, roleId: string) => void;
}

const ProjectMembersContext = createContext<
    ProjectMembersContextProps | undefined
>(undefined);

function useProjectMembers(): ProjectMembersContextProps {
    const ctx = useContext(ProjectMembersContext);
    if (!ctx) {
        throw new Error(
            'useProjectMembers must be used within a ProjectMembersProvider',
        );
    }

    return { ...ctx };
}

function ProjectMembersProvider({
    children,
}: {
    children: ReactNode;
}): ReactNode {
    const [members, setMembers] = useState<ProjectMember[]>([]);
    const { project } = useProjectSummary();
    const helper = useMemo(() => new ProjectRolesHelper(project), [project]);

    useEffect(() => {
        const participants = project.participants ?? [];
        setMembers(
            participants
                .filter(
                    (participant): participant is OrganizationUser =>
                        participant !== null &&
                        helper.isMember(participant.user.id) &&
                        participant.__typename === 'ProjectUser' &&
                        Boolean(participant.role),
                )
                .reduce<ProjectMember[]>((acc, participant) => {
                    return [
                        ...acc,
                        {
                            id: participant.user.id,
                            firstName: participant.user.firstName,
                            lastName: participant.user.lastName,
                            ...helper.getProjectUserStatus(participant.user.id),
                            participant,
                        },
                    ];
                }, [])
                .sort((a, b) => helper.sortByRole(a, b)),
        );
    }, [project.participants, helper]);

    const selectedICSOs: ProjectMember[] = useMemo(() => {
        const participants = project.participants ?? [];
        return participants
            .filter(
                (participant): participant is OrganizationUser =>
                    participant !== null &&
                    helper.isIcsoOnly(participant.user.id) &&
                    !members.some(
                        (member) => member.id === participant.user.id,
                    ),
            )
            .map((participant) => ({
                id: participant.user.id,
                firstName: participant.user.firstName,
                lastName: participant.user.lastName,
                ...helper.getProjectUserStatus(participant.user.id),
                participant,
            }));
    }, [members, project, helper]);

    function removeMember(userId: string): void {
        setMembers((prev) => prev.filter((member) => member.id !== userId));
    }

    function addMember(user: User, roleId: string): void {
        const role = ProjectRolesHelper.Roles.find(({ id }) => id === roleId);
        setMembers((prev) =>
            [
                ...prev.filter((member) => member.id !== user.id),
                {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    role,
                    icso: helper.isIcso(user.id),
                    participant: {
                        id: '',
                        role,
                        user,
                    },
                },
            ].sort((a, b) => helper.sortByRole(a, b)),
        );
    }

    return (
        <ProjectMembersContext.Provider
            value={{
                selectedMembers: members,
                selectedICSOs,
                removeMember,
                addMember,
            }}
        >
            {children}
        </ProjectMembersContext.Provider>
    );
}

export { useProjectMembers, ProjectMembersProvider };
