export const getDateAsDateMonthYearString = (
    dateString: string,
    showTime = false,
) => {
    const asDate = new Date(dateString);
    return showTime
        ? asDate.toLocaleString([], {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
          })
        : asDate.toLocaleDateString([], {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
          });
};

export function mediumDate(input: string, locale = navigator.language) {
    return new Date(input).toLocaleDateString(locale, { dateStyle: 'medium' });
}

export function shortDate(input: string, locale = navigator.language) {
    if (!input || input.trim() === '') return '';
    return new Date(input).toLocaleDateString(locale, { dateStyle: 'short' });
}

export function shortDateTime(input: string, locale = navigator.language) {
    if (!input || input.trim() === '') return '';
    const d = new Date(input);
    return `${d.toLocaleDateString(locale, {
        dateStyle: 'short',
    })} ${d.toLocaleTimeString(locale, { timeStyle: 'medium' })}`;
}
