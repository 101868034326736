/**
 * Root Error Page
 * A generic error page that display a user friendly error message.
 * E.g. It catches any error thrown during an action, loader, or rendering.
 * @see https://reactrouter.com/en/main/route/error-element
 * @see https://reactrouter.com/en/main/hooks/use-route-error
 */

import styled from 'styled-components';
import { Button } from '@ics-portal/react';
import {
    isRouteErrorResponse,
    Link,
    useNavigate,
    useRouteError,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

const PageWrapper = styled.div`
    height: 50ch;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;

    h1 {
        font-family: 'Siemens Sans', sans-serif;
        font-weight: 700;
        color: var(--ics-text-primary);
        margin: 0 auto;
    }
`;

const Number = styled.span`
    color: var(--ics-primary-purple-p90);
    display: block;

    margin-top: -0.25em;
`;

const BackButton = styled(Button)`
    margin: 2em auto;
`;

interface CustomErrorResponse {
    errorCode: number;
    title: string;
    description: string;
    backToUrl: string;
}

function getErrorResponse(statusCode: number): CustomErrorResponse {
    switch (statusCode) {
        case 403:
            return {
                errorCode: 403,
                title: 'Unauthorized Access',
                description:
                    'You do not have permission to view this page/resource.',
                backToUrl: '/',
            };

        case 404:
            return {
                errorCode: 404,
                title: 'Page not found',
                description: 'The page you are looking for does not exist.',
                backToUrl: '/',
            };

        default:
            return {
                errorCode: statusCode,
                title: 'Uknown error',
                description:
                    'An unknown error has occurred. If you keep seeing this issue, please contact support.',
                backToUrl: '/',
            };
    }
}

function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    // Handle any Response thrown in a loader or action
    if (isRouteErrorResponse(error)) {
        const { errorCode, title, description, backToUrl } = getErrorResponse(
            error.status,
        );

        return (
            <PageWrapper>
                <div>
                    <h1>
                        <Number>{errorCode}</Number>
                        <span>{title}</span>
                    </h1>
                    <p>
                        <span>{description}</span>
                    </p>
                    <BackButton
                        as={Link}
                        label="Go back to start"
                        role="link"
                        to={backToUrl}
                    />
                </div>
            </PageWrapper>
        );
    }

    if (error instanceof Error) {
        return (
            <PageWrapper>
                <div>
                    <h1>
                        <Number>Unknown error</Number>
                        <span>
                            Something unexpected occured, try and reload the
                            browser or click the button to go back.
                        </span>
                    </h1>
                    <p>
                        <span>{error.message}</span>
                    </p>
                    <BackButton
                        label="Go back"
                        onClick={() => {
                            navigate(-1);
                        }}
                        role="link"
                    />
                </div>
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <div>
                <h1>
                    <Number>Unknown error</Number>
                    <span>
                        Something unexpected occured, try and reload the browser
                        or click the button to go back.
                    </span>
                </h1>
                <BackButton
                    label="Go back"
                    onClick={() => {
                        navigate(-1);
                    }}
                    role="link"
                />
            </div>
        </PageWrapper>
    );
}

export default ErrorPage;
