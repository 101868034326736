import { graphql } from '~/gql';

export const GET_PROFILE_NAME_ONLY = graphql(`
    query GetProfileName($profileId: ID!) {
        standardProfiles(where: { id: $profileId }) {
            edges {
                node {
                    name
                    standard {
                        id
                    }
                }
            }
        }
    }
`);

export const GET_SINGLE_STANDARD = graphql(`
    query GetStandard($sid: ID!) {
        standards(where: { id: $sid }) {
            edges {
                node {
                    id
                    name
                    validFrom
                    validTo
                    releasedAt
                    requirementsCount
                    description
                    implementationGuidance
                    country {
                        name
                    }
                    standardProfiles {
                        name
                        alias
                    }
                }
            }
        }
    }
`);

export const GET_ALL_STANDARD_PROFILES = graphql(`
    query GetStandardProfiles {
        standardProfiles {
            edges {
                node {
                    id
                    name
                    alias
                    standard {
                        id
                        name
                        releasedAt
                    }
                }
            }
        }
    }
`);
