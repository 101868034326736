import { Flex, ListableInfo } from '@ics-portal/react';
import { useMutation, useQuery } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import type { ReactNode } from 'react';
import type { Project, UpdateProjectInput } from '~/gql/graphql';
import { GET_PROJECT_STATUSES_AND_PHASES } from '~/api/queries/projects';
import { UPDATE_PROJECT } from '~/api/mutations/projects';
import { useSnackbar } from '~/contexts/GlobalUiContext';
import { useProjectSummary } from '../../../_utils/contexts/ProjectContext';

const Select = styled.select`
    width: 100%;
    margin-bottom: 0.1825rem;
    border: none;
    color: var(--ics-text-secondary);
    font-family: 'Siemens Sans', sans-serif;
    font-size: 0.75rem;
    text-transform: capitalize;
`;

function ProjectDetails(): ReactNode {
    const { project } = useProjectSummary();

    const pushSnackbar = useSnackbar();
    const { data } = useQuery(GET_PROJECT_STATUSES_AND_PHASES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            projectTypeId: project.type?.id ?? '',
        },
        skip: !project.type?.id,
        onCompleted: () => {
            reset({
                id: project.id,
                statusID: project.status?.id,
                phaseID: project.phase?.id,
            });
        },
    });
    const [updateProject] = useMutation(UPDATE_PROJECT);
    const { register, reset, handleSubmit } = useForm<UpdateProjectInput>();

    const onSubmit: SubmitHandler<UpdateProjectInput> = (data) => {
        updateProject({ variables: data })
            .then((response) => {
                pushSnackbar({
                    type: 'success',
                    content: `Project ${response.data?.updateProject?.name} updated.`,
                });
            })
            .catch((err) => {
                pushSnackbar({
                    type: 'error',
                    content: `Error: ${err.message}`,
                });
            });
    };

    return (
        <Flex ai="stretch" direction="column" gap="xs">
            <ListableInfo label="Type:" value={project.type?.name ?? '-'} />
            <ListableInfo
                label="Organization:"
                value={project.organization?.name ?? '-'}
            />
            <form onChange={handleSubmit(onSubmit)}>
                <input id="id" type="hidden" {...register('id')} />
                <ListableInfo
                    label="Status:"
                    value={
                        <Select id="statusID" {...register('statusID')}>
                            {data?.projectStatuses.edges.map((edge) => (
                                <option key={edge.node.id} value={edge.node.id}>
                                    {edge.node.name}
                                </option>
                            ))}
                        </Select>
                    }
                />
                <ListableInfo
                    label="Phase:"
                    value={
                        <Select id="phaseID" {...register('phaseID')}>
                            {data?.projectPhases.edges.map((edge) => (
                                <option key={edge.node.id} value={edge.node.id}>
                                    {edge.node.name}
                                </option>
                            ))}
                        </Select>
                    }
                />
            </form>
        </Flex>
    );
}

export default ProjectDetails;
