import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';
import { useRef } from 'react';

/**
 * Renders an Icon from the Material Symbols library.
 * It accepts any valid <span> tag properties.
 */
function Icon(_a) {
  var {
      active = false,
      fontSize = 'normal',
      className,
      name
    } = _a,
    rest = __rest(_a, ["active", "fontSize", "className", "name"]);
  const hasBeenActiveRef = useRef(false);
  const classNames = clsx('ics-icon', {
    'ics-icon--filled': active,
    'ics-icon--unfilled': !active && hasBeenActiveRef.current,
    'ics-icon--small': fontSize === 'small'
  }, className);
  if (!hasBeenActiveRef.current && active) {
    hasBeenActiveRef.current = true;
  }
  return jsx("span", Object.assign({
    className: classNames
  }, rest, {
    children: name
  }));
}

export { Icon };
