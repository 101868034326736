import { Avatar } from '@ics-portal/react';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import { type ReactNode } from 'react';
import useUser from '../../../hooks/useUser';
import { useGlobalUI } from '../../../contexts/GlobalUiContext';
import s from './ProfileLink.module.css';

export default function ProfileLink(): ReactNode {
    const user = useUser();
    const { appClassName } = useGlobalUI();
    if (!user) return null;

    const fullName = `${user.firstName} ${user.lastName}`;
    const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

    const classes = clsx(s.profile, {
        [s.imageOnly]: appClassName === 'collapsed',
    });

    return (
        <NavLink to="/me" title={fullName} className={classes}>
            <Avatar size="large">{initials}</Avatar>
            <span className={s.fullName}>{fullName}</span>
        </NavLink>
    );
}
