import { Flex } from '@ics-portal/react';
import type { ReactNode } from 'react';
import HelpLink from './Links/HelpLink';

interface TitleWithHelpLinkProps {
    label: string;
    link: string;
}

export default function TitleWithHelpLink({
    label,
    link,
}: TitleWithHelpLinkProps): ReactNode {
    return (
        <Flex gap="sm" jc="flex-start">
            <h1>{label}</h1>
            <HelpLink href={link} />
        </Flex>
    );
}
