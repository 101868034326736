import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { clsx } from 'clsx';
import { Button } from '../Button/Button.js';

/**
 * Blankslate is used as placeholder to tell users why content is missing.
 * It can be used to guide users to take action.
 */
function BlankSlate(_a) {
  var {
      children,
      narrow,
      spacious,
      className
    } = _a,
    rest = __rest(_a, ["children", "narrow", "spacious", "className"]);
  const classes = clsx('ics-blank-slate', {
    'ics-blank-slate--narrow': narrow,
    'ics-blank-slate--spacious': spacious
  }, className);
  return jsx("div", Object.assign({
    className: classes
  }, rest, {
    children: children
  }));
}
function Visual(_a) {
  var {
      children,
      className
    } = _a,
    rest = __rest(_a, ["children", "className"]);
  const classes = clsx('ics-blank-slate__visual', className);
  return jsx("span", Object.assign({
    className: classes
  }, rest, {
    children: children
  }));
}
function Heading(_a) {
  var {
      as = 'h2',
      children,
      className
    } = _a,
    rest = __rest(_a, ["as", "children", "className"]);
  const Element = as;
  const classes = clsx('ics-blank-slate__heading', className);
  return jsx(Element, Object.assign({
    className: classes
  }, rest, {
    children: children
  }));
}
function Description(_a) {
  var {
      children,
      className
    } = _a,
    rest = __rest(_a, ["children", "className"]);
  const classes = clsx('ics-blank-slate__description', className);
  return jsx("p", Object.assign({
    className: classes
  }, rest, {
    children: children
  }));
}
function PrimaryAction(_a) {
  var {
      children,
      className,
      buttonVariant = 'primary',
      onClick
    } = _a,
    rest = __rest(_a, ["children", "className", "buttonVariant", "onClick"]);
  const classes = clsx('ics-blank-slate__action', className);
  return jsx("div", Object.assign({
    className: classes
  }, rest, {
    children: jsx(Button, {
      variant: buttonVariant,
      label: children,
      onClick: onClick
    })
  }));
}
var BlankSlate$1 = Object.assign(BlankSlate, {
  Visual,
  Heading,
  Description,
  PrimaryAction
});

export { BlankSlate$1 as default };
