import { jsx } from 'react/jsx-runtime';
import { Ratings } from './ratings.js';

const RATING_NUMBER_MAP = {
  [Ratings.LOW]: 1,
  [Ratings.MEDIUM]: 2,
  [Ratings.HIGH]: 3,
  [Ratings.VERYHIGH]: 4
};
function RiskRating(_ref) {
  let {
    rating,
    style,
    className
  } = _ref;
  if (!rating) {
    return null;
  }
  const numberRating = typeof rating === 'number' ? rating : RATING_NUMBER_MAP[rating];
  /**
   * Create an array of the length of the passed in rating
   * e.g. if rating is 3, create an array of 3 elements
   */
  const array = Array.from({
    length: numberRating
  }, (_, i) => i);
  const classNames = ['ics-risk-rating', `ics-risk-rating--rating-${numberRating.toString()}`, className].join(' ');
  return jsx("div", {
    className: classNames,
    style: style,
    children: array.map(i => jsx("div", {
      className: "ics-risk-rating__box"
    }, i))
  });
}

export { RiskRating };
