import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export default function createPublicClientApplication(
    config: Configuration,
): PublicClientApplication {
    const instance = new PublicClientApplication(config);
    const accounts = instance.getAllAccounts();

    if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
    }
    return instance;
}
