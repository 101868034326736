import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';

/**
 * Used to create GridTemplate composition
 */
function GridTemplate(_a) {
  var {
      children,
      className
    } = _a,
    props = __rest(_a, ["children", "className"]);
  return jsx("div", {
    className: className,
    style: Object.assign(Object.assign(Object.assign({
      display: 'grid',
      gap: props.gap ? `var(--ics-gutter-${props.gap})` : '0'
    }, props.col ? {
      gridTemplateColumns: props.col
    } : {}), props.row ? {
      gridTemplateRows: props.row
    } : {}), props.flow ? {
      gridAutoFlow: props.flow
    } : {}),
    children: children
  });
}

export { GridTemplate };
