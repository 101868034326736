import type { ReactNode } from 'react';
import { Tooltip, TooltipTrigger, TooltipContent } from '@ics-portal/react';
import { HelpIcon } from '../../_atoms/icons';

interface HelpLinkProps {
    href: string;
}

export default function HelpLink({ href }: HelpLinkProps): ReactNode {
    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <a
                    href={href}
                    target="_blank"
                    rel="help noreferrer"
                    style={{
                        color: 'var(--ics-neutrals-n60)',
                        display: 'inline-block',
                        height: '1.5rem', // same as the icon height
                    }}
                >
                    <HelpIcon />
                </a>
            </TooltipTrigger>
            <TooltipContent>Go to the help documentation page</TooltipContent>
        </Tooltip>
    );
}
