import { styled } from 'styled-components';
import { useGlobalUI } from '../../../contexts/GlobalUiContext';
import { ChevronRightIcon } from '../../_atoms/icons';

const ToggleButton = styled.button`
    position: absolute;
    left: 100%;
    top: 3rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0 0.625rem 0.625rem 0;
    color: white;
    background-color: var(--ics-primary-dark-purple-se70);
    width: 2.5rem;
    aspect-ratio: 1;
    cursor: pointer;
    .ics-icon {
        transition: transform 300ms ease;
    }
    &.expanded .ics-icon {
        transform: rotateY(-180deg);
    }
    .scaled-up & {
        top: 4.1rem;
    }
`;

export default function AppSidebarToggle(): JSX.Element {
    const { setAppClassName, appClassName } = useGlobalUI();

    function toggleSidebar(): void {
        setAppClassName((prev) =>
            prev === 'expanded' ? 'collapsed' : 'expanded',
        );
    }

    return (
        <ToggleButton
            className={appClassName}
            onClick={toggleSidebar}
            title="Toggle sidebar width"
            type="button"
        >
            <ChevronRightIcon />
        </ToggleButton>
    );
}
