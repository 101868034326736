import { graphql } from '~/gql';

export const GET_ZONE_DETAILS = graphql(`
    query getZoneDetails($pzid: ID!) {
        projectZones(where: { id: $pzid }) {
            edges {
                node {
                    id
                    name
                    description
                    parentZone {
                        id
                        name
                    }
                    exposureRating
                    assetsCount
                }
            }
        }
        projectZoneStandardProfiles(where: { projectZoneID: $pzid }) {
            edges {
                node {
                    standardProfile {
                        id
                        name
                        alias
                        standard {
                            id
                            name
                            releasedAt
                        }
                    }
                }
            }
        }
    }
`);

export const GET_SINGLE_ZONE_NAME_ONLY = graphql(`
    query getZoneName($pzid: ID!) {
        projectZones(where: { id: $pzid }) {
            edges {
                node {
                    name
                }
            }
        }
    }
`);

export const GET_ALL_COMMENTS = graphql(`
    query GetComments($rqid: ID!, $spid: ID!, $pzid: ID!) {
        projectZoneStandardProfileRequirementCommentsGet(
            requirementID: $rqid
            standardProfileID: $spid
            projectZoneID: $pzid
        ) {
            ...Comment
        }
    }
`);

// Because Graphql doesn't handle recursivity we have to do this funny mess :)
export const GET_PROJECT_ZONES_TREE = graphql(`
    query GetZonesTreeRec($pid: ID!) {
        projectProjectZonesHierarchy(projectID: $pid) {
            ...ProjectZoneEnjoyment
            children {
                ...ProjectZoneEnjoyment
                children {
                    ...ProjectZoneEnjoyment
                    children {
                        ...ProjectZoneEnjoyment
                        children {
                            ...ProjectZoneEnjoyment
                            children {
                                ...ProjectZoneEnjoyment
                                children {
                                    ...ProjectZoneEnjoyment
                                    children {
                                        ...ProjectZoneEnjoyment
                                        children {
                                            ...ProjectZoneEnjoyment
                                            children {
                                                ...ProjectZoneEnjoyment
                                                children {
                                                    ...ProjectZoneEnjoyment
                                                    children {
                                                        ...ProjectZoneEnjoyment
                                                        children {
                                                            ...ProjectZoneEnjoyment
                                                            children {
                                                                ...ProjectZoneEnjoyment
                                                                children {
                                                                    ...ProjectZoneEnjoyment
                                                                    children {
                                                                        ...ProjectZoneEnjoyment
                                                                        children {
                                                                            ...ProjectZoneEnjoyment
                                                                            children {
                                                                                ...ProjectZoneEnjoyment
                                                                                children {
                                                                                    ...ProjectZoneEnjoyment
                                                                                    children {
                                                                                        ...ProjectZoneEnjoyment
                                                                                        children {
                                                                                            ...ProjectZoneEnjoyment
                                                                                            children {
                                                                                                ...ProjectZoneEnjoyment
                                                                                                children {
                                                                                                    ...ProjectZoneEnjoyment
                                                                                                    children {
                                                                                                        ...ProjectZoneEnjoyment
                                                                                                        children {
                                                                                                            ...ProjectZoneEnjoyment
                                                                                                            children {
                                                                                                                ...ProjectZoneEnjoyment
                                                                                                                children {
                                                                                                                    ...ProjectZoneEnjoyment
                                                                                                                    children {
                                                                                                                        ...ProjectZoneEnjoyment
                                                                                                                        children {
                                                                                                                            ...ProjectZoneEnjoyment
                                                                                                                            children {
                                                                                                                                ...ProjectZoneEnjoyment
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`);
