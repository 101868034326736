import { graphql } from '~/gql';

export const GET_PROJECT_TASKS = graphql(`
    query GetProjectTasks($pid: ID!) {
        tasks(where: { projectID: $pid }) {
            ...TasksConnectionPartial
        }
    }
`);

export const GET_TASKS = graphql(`
    query GetTasks($where: TaskWhereInput) {
        tasks(where: $where) {
            ...TasksConnectionPartial
        }
    }
`);

export const GET_GLOBAL_TASKS = graphql(`
    query GetGlobalTasks($uid: ID!) {
        tasks(where: { assigneeID: $uid }) {
            ...TasksConnectionPartial
        }
    }
`);

export const GET_TASK_COMMENTS = graphql(`
    query GetTasksComments($tid: ID!) {
        tasks(where: { id: $tid }) {
            edges {
                node {
                    id
                    taskComments {
                        ...TasksCommentPartial
                    }
                }
            }
        }
    }
`);

export const EXPORT_TASKS = graphql(`
    query ExportProjectTasks($id: ID!, $taskIDs: [ID!]) {
        exportProjectTasks(id: $id, taskIDs: $taskIDs) {
            fileName
            content
            type
        }
    }
`);
