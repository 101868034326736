import { graphql } from '~/gql';

export const SET_PROJECT_USERS = graphql(`
    mutation setUsers($id: ID!, $users: [ProjectUserInput!]) {
        setProjectUsers(input: { id: $id, users: $users }) {
            user {
                id
                firstName
                lastName
            }
            role {
                id
                name
            }
        }
    }
`);

export const CREATE_PROJECT = graphql(`
    mutation CreateProject(
        $name: String!
        $description: String!
        $statusID: ID!
        $phaseID: ID!
        $typeID: ID!
        $organizationID: ID!
        $customerName: String
        $customerEmail: String
        $customerPhone: String
        $customerAddress: String
        $locationStreet: String
        $locationPostalCode: String
        $locationCity: String
        $locationCountry: String
        $plantName: String
        $plantExternalID: String
    ) {
        createProject(
            input: {
                name: $name
                description: $description
                organizationID: $organizationID
                statusID: $statusID
                phaseID: $phaseID
                typeID: $typeID
                customerName: $customerName
                customerEmail: $customerEmail
                customerPhone: $customerPhone
                customerAddress: $customerAddress
                locationStreet: $locationStreet
                locationPostalCode: $locationPostalCode
                locationCity: $locationCity
                locationCountry: $locationCountry
                plantName: $plantName
                plantExternalID: $plantExternalID
            }
        ) {
            id
            name
            description
            creator {
                id
                gid
                firstName
                lastName
                email
            }
            organization {
                id
                name
            }
            phase {
                id
                name
            }
            status {
                id
                name
            }
            type {
                id
                name
            }
            updateTime
            createTime
            ...ProjectLocationPartial
            ...ProjectPlantPartial
        }
    }
`);

export const UPDATE_PROJECT = graphql(`
    mutation UpdateProject(
        $id: ID!
        $name: String
        $description: String
        $statusID: ID
        $phaseID: ID
        $typeID: ID
        $organizationID: ID
        $customerName: String
        $customerEmail: String
        $customerPhone: String
        $customerAddress: String
        $locationStreet: String
        $locationPostalCode: String
        $locationCity: String
        $locationCountry: String
        $plantName: String
        $plantExternalID: String
    ) {
        updateProject(
            input: {
                id: $id
                name: $name
                description: $description
                organizationID: $organizationID
                statusID: $statusID
                phaseID: $phaseID
                typeID: $typeID
                customerName: $customerName
                customerEmail: $customerEmail
                customerPhone: $customerPhone
                customerAddress: $customerAddress
                locationStreet: $locationStreet
                locationPostalCode: $locationPostalCode
                locationCity: $locationCity
                locationCountry: $locationCountry
                plantName: $plantName
                plantExternalID: $plantExternalID
            }
        ) {
            id
            name
            description
            creator {
                id
                gid
                firstName
                lastName
                email
            }
            organization {
                id
                name
            }
            phase {
                id
                name
            }
            status {
                id
                name
            }
            type {
                id
                name
            }
            updateTime
            createTime
            ...ProjectCustomerPartial
            ...ProjectLocationPartial
            ...ProjectPlantPartial
        }
    }
`);

export const COPY_PROJECT = graphql(`
    mutation CopyProject(
        $copyInventory: Boolean!
        $copyMeasures: Boolean!
        $copyProjectZones: Boolean!
        $copyPssClassification: Boolean!
        $copyTasks: Boolean!
        $description: String!
        $id: ID!
        $name: String!
        $organizationID: ID!
        $phaseID: ID!
        $statusID: ID!
    ) {
        copyProject(
            input: {
                copyInventory: $copyInventory
                copyMeasures: $copyMeasures
                copyProjectZones: $copyProjectZones
                copyPssClassification: $copyPssClassification
                copyTasks: $copyTasks
                description: $description
                id: $id
                name: $name
                organizationID: $organizationID
                phaseID: $phaseID
                statusID: $statusID
            }
        ) {
            id
            name
            description
            creator {
                id
                gid
                firstName
                lastName
                email
            }
            organization {
                id
                name
            }
            phase {
                id
                name
            }
            status {
                id
                name
            }
            type {
                id
                name
            }
            updateTime
            createTime
            ...ProjectCustomerPartial
            ...ProjectLocationPartial
            ...ProjectPlantPartial
        }
    }
`);

export const DELETE_PROJECT = graphql(`
    mutation DeleteProject($id: ID!) {
        deleteProject(id: $id) {
            id
        }
    }
`);

export const CREATE_PROJECT_ZONE = graphql(`
    mutation createProjectZone(
        $name: String!
        $description: String!
        $projectId: ID!
        $parentZoneId: ID!
        $exposureRating: ProjectZoneExposureRating!
        $standardProfileIds: [ID!]!
    ) {
        saveProjectZone(
            input: {
                name: $name
                description: $description
                projectID: $projectId
                parentZoneID: $parentZoneId
                exposureRating: $exposureRating
                standardProfileIDs: $standardProfileIds
                isInternal: true
            }
        ) {
            id
            name
        }
    }
`);

export const UPDATE_PROJECT_ZONE = graphql(`
    mutation updateProjectZone(
        $id: ID
        $name: String!
        $description: String!
        $projectId: ID!
        $parentZoneId: ID
        $exposureRating: ProjectZoneExposureRating!
        $standardProfileIds: [ID!]!
    ) {
        saveProjectZone(
            input: {
                id: $id
                name: $name
                description: $description
                projectID: $projectId
                parentZoneID: $parentZoneId
                exposureRating: $exposureRating
                standardProfileIDs: $standardProfileIds
                isInternal: true
            }
        ) {
            id
        }
    }
`);

export const DELETE_PROJECT_ZONE = graphql(`
    mutation DeleteProjectZone($id: ID!) {
        deleteProjectZone(id: $id) {
            id
        }
    }
`);
