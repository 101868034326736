import { Flex, IconButton } from '@ics-portal/react';
import { useState } from 'react';
import { CompactViewIcon, RoomyViewIcon } from './_atoms/icons';

function ViewModeSelector() {
    const [viewMode, setViewMode] = useState<'compact' | 'normal'>(
        document.body.classList.contains('scaled-up') ? 'normal' : 'compact',
    );
    const handleChangeView = (view: 'compact' | 'normal') => {
        setViewMode(view);
        if (view === 'normal') {
            document.body.classList.add('scaled-up');
            return;
        }
        document.body.classList.remove('scaled-up');
    };

    return (
        <Flex jc="flex-start">
            <IconButton
                disabled={viewMode === 'compact'}
                onClick={() => {
                    handleChangeView('compact');
                }}
            >
                <CompactViewIcon fontSize="small" />
            </IconButton>
            <IconButton
                disabled={viewMode !== 'compact'}
                onClick={() => {
                    handleChangeView('normal');
                }}
            >
                <RoomyViewIcon fontSize="small" />
            </IconButton>
        </Flex>
    );
}

export default ViewModeSelector;
