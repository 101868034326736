import { graphql } from '~/gql';

export const GET_USERS = graphql(`
    query GetUsers {
        users {
            totalCount
            edges {
                node {
                    id
                    gid
                    firstName
                    lastName
                    orgCode
                    email
                    location
                    are
                    admin
                    active
                    organizations {
                        id
                        organization {
                            id
                            name
                        }
                        role
                    }
                }
            }
        }
    }
`);

export const GET_USER_OF_PROJECT = graphql(`
    query usersOfProject($projectId: ID!) {
        users(where: { hasProjectsWith: { id: $projectId } }) {
            totalCount
            edges {
                node {
                    id
                    firstName
                    lastName
                    email
                }
            }
        }
    }
`);

export const FIND_USERS = graphql(`
    query FindUsers($search: String!) {
        users(
            where: {
                or: [
                    { firstNameContainsFold: $search }
                    { lastNameContainsFold: $search }
                ]
                active: true
            }
        ) {
            edges {
                node {
                    ...MinimalUserPartial
                }
            }
        }
    }
`);

export const ME = graphql(`
    query GetMe {
        me {
            id
            gid
            firstName
            lastName
            email
            orgCode
            location
            are
            admin
            active
        }
    }
`);

export const GET_USER_NOTIFICATION_SETTINGS = graphql(`
    query GetUserNotificationSettings {
        userNotificationSetting {
            id
            userID
            enable
            frequency
            projects {
                id
                priorityEnabled
                cvssEnabled
                cvssMinScore
                minLevelEnabled
                minLevel
                assetsAndZonesEnabled
                assignedOnly
                priority
                tasksEnabled
                vulnerabilitiesEnabled
            }
        }
    }
`);
