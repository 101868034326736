import { graphql } from '~/gql';

export const GET_MINIMAL_PROJECT_SURVEY = graphql(`
    query getMinimalSurveyForProject($id: ID!) {
        projectSurvey(projectID: $id) {
            ...BaseSurveyPartial
            form {
                version
            }
        }
    }
`);

export const GET_PROJECT_SURVEY = graphql(`
    query getSurveyForProject($id: ID!) {
        projectSurvey(projectID: $id) {
            ...BaseSurveyPartial
            answers
            form {
                version
                ...SurveyFormPartial
            }
        }
    }
`);

export const GET_LATEST_SURVEY_FORM_WITH_PROFILES = graphql(`
    query activeLatestSurveryFormWithProfiles($id: ID) {
        surveyForms(
            where: {
                isPublished: true
                kind: PSSCLASSIFICATION
                hasContentsWith: { profileID: $id }
            }
            orderBy: { direction: DESC, field: VERSION }
            first: 1
        ) {
            totalCount
            edges {
                node {
                    ...SurveyFormPartial
                    id
                    version
                    isPublished
                    profiles {
                        id
                        name
                        securityActivities
                    }
                }
            }
        }
    }
`);

export const GET_SURVEY_FORM = graphql(`
    query GetSurveyForm($id: ID) {
        surveyForms(where: { hasContentsWith: { profileID: $id } }) {
            edges {
                node {
                    ...SurveyFormPartial
                }
            }
        }
    }
`);

export const GET_SURVEY_PROFILES = graphql(`
    query GetSurveyProfiles {
        surveyProfiles {
            edges {
                node {
                    id
                    name
                    perequisties
                    securityActivities
                }
            }
        }
    }
`);

export const GET_SURVEY_FORMS = graphql(`
    query surveryFormsWithProfiles {
        surveyForms(
            where: { isPublished: true, kind: PSSCLASSIFICATION }
            orderBy: { direction: DESC, field: VERSION }
        ) {
            totalCount
            edges {
                node {
                    id
                    version
                    kind
                    profiles {
                        id
                        name
                        securityActivities
                    }
                }
            }
        }
    }
`);

export const GET_LATEST_PSSP_SURVEY_FORM_WITH_PROFILES = graphql(`
    query getLatestPsspSurveyFormWithProfiles {
        surveyForms(
            where: { isPublished: true, kind: PSSCLASSIFICATION }
            orderBy: { direction: DESC, field: VERSION }
            first: 1
        ) {
            totalCount
            edges {
                node {
                    id
                    version
                    profiles {
                        id
                        name
                        securityActivities
                        perequisties
                    }
                }
            }
        }
    }
`);

export const GET_SURVEY_FORM_CONTENT = graphql(`
    query surveyFormContentList($profileID: ID) {
        surveyFormContents(where: { profileID: $profileID }) {
            totalCount
            edges {
                node {
                    id
                    ...SurveyFormContentPartial
                    form {
                        id
                        version
                        isPublished
                        profiles {
                            id
                            name
                            securityActivities
                        }
                    }
                    profile {
                        id
                        name
                        securityActivities
                    }
                }
            }
        }
    }
`);

export const GET_PROJECT_PSS_REPORT = graphql(`
    query GetProjectSurveyReport($projectId: ID!) {
        projectSurveyReport(
            projectID: $projectId
            surveyKind: PSSCLASSIFICATION
        ) {
            fileName
            content
            type
        }
    }
`);
