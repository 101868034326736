import { createContext, useContext } from 'react';
import { type Project } from '~/gql/graphql';

const ProjectContext = createContext<{ project: Project | undefined }>({
    project: undefined,
});

function useProjectSummary(): { project: Project } {
    const { project } = useContext(ProjectContext);
    if (!project) {
        throw new Error(
            'useProjectSummary must be used within a ProjectSummaryProvider',
        );
    }
    return { project };
}

export { useProjectSummary, ProjectContext };
