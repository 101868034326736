import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;

type Props = {
    children: ReactNode;
    id?: string;
};

const VisuallyHidden = ({ children, id }: Props) => {
    if (id) {
        return <StyledDiv id={id}>{children}</StyledDiv>;
    }
    return <StyledDiv>{children}</StyledDiv>;
};

export default VisuallyHidden;
