import type { ExportAssetsList } from '../interfaces';

function s2ab(s: string) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}

/**
 * Save a base64 encoded string as file
 * @param fileName The name of the file
 * @param data The base64 encoded string
 * @param fileType The type of the file
 */
export function saveBase64asFile(
    fileName: string,
    data: string,
    fileType: string,
) {
    // Convert content string to binary data
    const binaryData = window.atob(data);
    // Create a Blob object representing the data
    const blob = new Blob(
        [new Uint8Array([...binaryData].map((char) => char.charCodeAt(0)))],
        { type: fileType },
    );
    // Create a download link and click it, clean up after
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export function saveExcelExportAsFile({
    fileName,
    type,
    content,
}: ExportAssetsList) {
    const errors = [];
    if (!fileName) errors.push('No filename provided');
    if (!content) errors.push('No content found');
    if (type !== 'application/vnd.ms-excel') errors.push('Wrong filetype');
    if (errors.length > 0) {
        console.error({ errors });
        return;
    }
    saveBase64asFile(fileName, content, type);
}

export function saveDocExportAsFile({
    fileName,
    type,
    content,
}: ExportAssetsList) {
    const errors = [];
    if (!fileName) errors.push('No filename provided');
    if (!content) errors.push('No content found');
    if (
        type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
        errors.push('Wrong filetype');
    if (errors.length > 0) {
        throw new Error(errors.join('. '));
    }
    saveBase64asFile(fileName, content, type);
}

export function saveStringAsJsonFile(name: string, data: string, type: string) {
    const blob = new Blob([data], {
        type,
    });
    const objectURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = objectURL;
    anchor.download = name;
    anchor.click();

    URL.revokeObjectURL(objectURL);
}

export function primitiveArraysAreEqual(array1: string[], array2: string[]) {
    return (
        array1.length === array2.length &&
        array1.every((element, index) => element === array2[index])
    );
}
