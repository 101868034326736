import { MinimalUserPartialFragment } from '../gql/graphql';
import { User } from '../interfaces';

export const capitalizeString = (str: string) => {
    const newStr = str.toLowerCase();
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
};

export function simplePluralize(input: string, count: number) {
    return count === 0 || count > 1 ? `${input}s` : input;
}

export const fullName = (
    actor: User | MinimalUserPartialFragment | undefined | null,
): string => (actor ? `${actor.firstName ?? ''} ${actor.lastName ?? ''}` : '');
