import {
    AdministrationIcon,
    ComponentLibraryIcon,
    MyTaskIcon,
    ProjectsIcon,
    SecurityNotificationIcon,
    StandardsLibraryIcon,
} from '../../components/_atoms/icons';
import type { Navigation } from '../../interfaces/IMenuData';

const DefaultMenu: Navigation = {
    id: 'default',
    items: [
        {
            label: 'Projects',
            to: '/projects',
            icon: {
                default: ProjectsIcon,
            },
        },
        {
            label: 'Security Notifications',
            to: '/security-notifications',
            icon: {
                default: SecurityNotificationIcon,
            },
        },
        {
            label: 'My Tasks',
            to: '/tasks',
            icon: {
                default: MyTaskIcon,
            },
        },
        {
            label: 'Component Library',
            to: '/component-library',
            icon: {
                default: ComponentLibraryIcon,
            },
        },
        {
            label: 'Standards Library',
            to: '/standards',
            icon: {
                default: StandardsLibraryIcon,
            },
        },
        {
            label: 'Administration',
            to: '/admin',
            icon: {
                default: AdministrationIcon,
            },
            admin: true,
        },
    ],
};

export default DefaultMenu;
