import {
    FloatingFocusManager,
    FloatingPortal,
    autoUpdate,
    offset,
    safePolygon,
    useClick,
    useFloating,
    useHover,
    useInteractions,
    type FloatingContext,
} from '@floating-ui/react';
import clsx from 'clsx';
import { forwardRef, useEffect, useState, type CSSProperties } from 'react';
import { NavLink, useLocation, type NavLinkProps } from 'react-router-dom';
import { isUserAdmin } from '../../../auth/authorization';
import { useGlobalUI } from '../../../contexts/GlobalUiContext';
import useUser from '../../../hooks/useUser';
import type { Navigation, NavigationItem } from '../../../interfaces/IMenuData';
import { ChevronUpIcon } from '../../_atoms/icons';
import s from './AppMenu.module.css';
import getNavigationItemIcon from './getNavItemIcon';

interface AppMenuProps {
    menu: Navigation;
    className?: string;
    style?: CSSProperties;
}

const AppMenu = forwardRef<HTMLElement, AppMenuProps>(function AppMenuComponent(
    { menu, className, style },
    forwardedRef,
) {
    const user = useUser();
    const isAdmin = user ? isUserAdmin(user) : false;
    const { appClassName } = useGlobalUI();
    const classes = clsx(s.nav, className, appClassName);

    return (
        <nav className={classes} ref={forwardedRef} style={style}>
            <ul className={s.list}>
                {menu.items.map((item) => {
                    // Guard any non-admin from seeing administration links
                    if (item.admin && !isAdmin) return null;
                    return (
                        <AppMenuLinkItem
                            key={item.to}
                            item={item}
                            exactActive={!item.subMenu}
                            collapsed={appClassName === 'collapsed'}
                        />
                    );
                })}
            </ul>
        </nav>
    );
});

AppMenu.displayName = 'AppMenu';

export default AppMenu;

interface AppMenuLinkItemProps {
    item: NavigationItem;
    // Change what is considered an exact active link depending on if the item has a submenu or not
    // @see https://reactrouter.com/en/main/components/nav-link#end
    exactActive?: NavLinkProps['end'];
    collapsed?: boolean;
}

function AppMenuLinkItem({
    item,
    exactActive,
    collapsed,
}: AppMenuLinkItemProps) {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Force close the submenu when the location changes
    useEffect(() => {
        setIsOpen(false);
    }, [location.pathname]);

    const { refs, floatingStyles, context } = useFloating({
        placement: 'right-start',
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset({
                mainAxis: 1, // offset the floating element horizontally
                crossAxis: -8, // offset the floating element vertically (align first item with icon)
            }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const hover = useHover(context, {
        handleClose: safePolygon(),
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        hover,
    ]);

    return (
        <li
            className={s.listItem}
            ref={refs.setReference}
            {...getReferenceProps()}
        >
            <NavLink
                to={item.to}
                title={item.label}
                className={s.link}
                end={exactActive}
            >
                {({ isActive }) => (
                    <>
                        {item.icon ? (
                            <div className={s.icon}>
                                {getNavigationItemIcon(item, isActive)}
                            </div>
                        ) : null}
                        <span className={s.label}>
                            <span>{item.label}</span>
                            {item.subMenu ? (
                                <div className={s.caret}>
                                    <ChevronUpIcon />
                                </div>
                            ) : null}
                        </span>
                    </>
                )}
            </NavLink>
            {item.subMenu && !collapsed ? (
                <SubMenu items={item.subMenu.items} />
            ) : null}
            {item.subMenu && collapsed && isOpen ? (
                <FloatingSubMenu
                    context={context}
                    items={item.subMenu.items}
                    ref={refs.setFloating}
                    style={floatingStyles}
                    {...getFloatingProps()}
                />
            ) : null}
        </li>
    );
}

interface SubMenuProps {
    items: NavigationItem[];
}

function SubMenu({ items }: SubMenuProps) {
    return (
        <nav className={s.subNav}>
            <ul>
                {items.map((item) => (
                    <li key={item.to}>
                        <NavLink
                            to={item.to}
                            title={item.label}
                            className={s.subNavLink}
                            end
                        >
                            {item.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

interface FloatingSubMenuProps {
    context: FloatingContext;
    items: NavigationItem[];
    style?: CSSProperties;
}

const FloatingSubMenu = forwardRef<HTMLElement, FloatingSubMenuProps>(
    function FloatingSubMenu(props, forwardedRef) {
        const { context, items, style, ...rest } = props;

        const classes = clsx(s.subNav, s.floatingSubNav);

        return (
            <FloatingPortal>
                <FloatingFocusManager context={context} modal>
                    <nav
                        ref={forwardedRef}
                        className={classes}
                        style={style}
                        {...rest}
                    >
                        <ul>
                            {items.map((item) => (
                                <li key={item.to}>
                                    <NavLink
                                        to={item.to}
                                        title={item.label}
                                        className={s.subNavLink}
                                        end
                                    >
                                        {item.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </FloatingFocusManager>
            </FloatingPortal>
        );
    },
);
